import React, { useEffect, useState } from 'react';
import { API_URL, getApi, getImgSrc, toggleLoader } from '../utils';
import { buildFlags, IMAGE_APPROACH_OPTIONS } from '../utils/constants/builtFromTop/buildFlags';
import { IWindow } from '../utils/types';

interface IImgComp {
  src: string;
  alt: string;
  'data-tooltip'?: string;
  className?: string;
  onClick?: () => void;
  loadHook?: () => void;
}

const isBase64Approach = buildFlags.IMAGE_APPROACH === IMAGE_APPROACH_OPTIONS.BASE64;
export const ImgComp = ({ src, alt, loadHook, ...rest }: IImgComp) => {
  const [hasImg, setHasImg] = useState(false);
  const getImg = async () => {
    await getApi(API_URL.IMAGE);
    setHasImg(true);
    setTimeout(() => loadHook && loadHook(), 0);
  };
  useEffect(() => {
    if (isBase64Approach) {
      getImg();
    } else {
      const cd = (window as unknown as IWindow).cd;
      toggleLoader();
      cd.imageFetching = false;
    }
    // eslint-disable-next-line
  }, []);
  return hasImg || !isBase64Approach ? <img src={getImgSrc(src)} alt={alt} {...rest} /> : <>...</>;
};
