import React, { useEffect, useRef, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { timeout } from '../../util';
import { IMsg, Prompt } from '../Prompt';
import { COLOR_LIST, SimpleColorPicker } from '../SimpleColorPicker';
import { resetSelection, restoreSelection, saveSelection, senitizeConfig } from './SenitizeConfig';

import {
  BackColor,
  Bold,
  CreateLink,
  Download,
  ForeColor,
  Html,
  InsertImage,
  InsertOrderedList,
  InsertTable,
  InsertUnorderedList,
  Italic,
  JustifyCenter,
  JustifyFull,
  JustifyLeft,
  JustifyRight,
  Preview,
  Redo,
  Underline,
  Undo,
} from './svg';

interface IRichTextEditor {
  className: string;
  id: string;
  onChange: (e: string) => void;
}

export const isRichTextEditorSupported = !!document.execCommand;

interface IPromptObj {
  showPrompt: boolean;
  userInput?: string[];
  promptContent?: IMsg[];
  defaultPromptValue: string[];
  cb?: null | ((p: string[]) => void);
}

export const RichTextEditor = ({ className, id, onChange }: IRichTextEditor) => {
  const [content, setContent] = useState('');
  const [isHtmlView, setIsHtmlView] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const selectionInEditable = useRef(null);

  const [promptObj, setPromptObj] = useState<IPromptObj>({
    showPrompt: false,
    userInput: [''],
    promptContent: [null],
    defaultPromptValue: [''],
    cb: null,
  });

  useEffect(() => {
    // @ts-ignore execCommand
    document.execCommand('defaultParagraphSeparator', false, 'p');
  }, []);

  useEffect(() => {
    const sanitised = sanitizeHtml(content, senitizeConfig);
    onChange(sanitised);
  }, [content]);
  useEffect(() => {
    if (promptObj.cb && !promptObj.showPrompt) {
      const { cb, ...rest } = promptObj;
      setPromptObj(rest);
      timeout(() => {
        editorRef.current?.focus();
        selectionInEditable.current && restoreSelection(selectionInEditable.current);
        timeout(() => {
          cb(promptObj.userInput || ['']);
          timeout(resetSelection);
        });
      });
    }
  }, [promptObj]);

  const execCommand = (command, value = null) => {
    // @ts-ignore execCommand
    document.execCommand(command, false, value);
  };

  const toggleHtmlView = () => {
    const _isHtmlView = !isHtmlView;
    setIsHtmlView(_isHtmlView);
    if (editorRef.current) {
      _isHtmlView ? setContent(editorRef.current.innerHTML) : (editorRef.current.innerHTML = content);
    }
  };
  const _insertTable = (rows?: number, cols?: number) => {
    if (!rows || !cols) return;

    let tableHtml = '<table border="1">';
    // @ts-ignore rows
    for (let i = 0; i < rows; i++) {
      tableHtml += '<tr>';
      // @ts-ignore cols
      for (let j = 0; j < cols; j++) {
        tableHtml += '<td>&nbsp;</td>'; // Non-breaking space for empty cells
      }
      tableHtml += '</tr>';
    }
    tableHtml += '</table>';
    // @ts-ignore insertHTML
    execCommand('insertHTML', tableHtml);
  };

  const handleChange = () => {
    // editorRef.current.textContent = e.target.textContent;
    // @ts-ignore setContent
    setContent(editorRef.current.innerHTML);
  };

  const downloadContent = () => {
    const element = document.createElement('a');
    const file = new Blob([content], { type: 'text/html' });
    element.href = URL.createObjectURL(file);
    element.download = 'editorData.html';
    document.body.appendChild(element);
    element.click();
  };

  const togglePromptOnn = (promptContent: IMsg[], defaultPromptValue: string[], cb: (p: string[]) => void) => {
    selectionInEditable.current = saveSelection();
    setPromptObj({
      ...promptObj,
      showPrompt: true,
      promptContent,
      defaultPromptValue,
      cb,
    });
  };

  const insertTable = () =>
    togglePromptOnn(['Enter number of rows', 'Enter number of columns'], ['2', '3'], ([rows, cols]) => {
      const [_rows, _cols] = [rows && parseInt(rows), cols && parseInt(cols)];
      return _rows && _cols && _insertTable(parseInt(`${_rows}`), parseInt(`${_cols}`));
    });
  const createLink = () =>
    togglePromptOnn(['Enter URL:'], ['https://akhileshcoder.com'], ([link]) => {
      // @ts-ignore createLink
      link && execCommand('createLink', link);
    });

  const insertImage = () =>
    togglePromptOnn(['Enter Image URL:'], ['https://akhileshcoder.com/pp2.png'], ([imgUrl]) => {
      // @ts-ignore insertImage
      imgUrl && execCommand('insertImage', imgUrl);
    });
  const backColor = () =>
    togglePromptOnn([SimpleColorPicker], [COLOR_LIST.BLUE], ([color]) => {
      // @ts-ignore insertImage
      color && execCommand('backColor', color);
    });
  const foreColor = () =>
    togglePromptOnn([SimpleColorPicker], [COLOR_LIST.GREEN], ([color]) => {
      // @ts-ignore insertImage
      color && execCommand('foreColor', color);
    });
  return (
    <>
      <div className="rich-text-editor">
        <div className="action-pane">
          <div className="btn-group">
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="bold"
              onClick={() => execCommand('bold')}
            >
              <Bold />
            </button>
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="italic"
              onClick={() => execCommand('italic')}
            >
              <Italic />
            </button>
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="underline"
              onClick={() => execCommand('underline')}
            >
              <Underline />
            </button>
          </div>
          <div className="btn-group">
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="Text fill color"
              onClick={backColor}
            >
              <BackColor />
            </button>
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="Text color"
              onClick={foreColor}
            >
              <ForeColor />
            </button>
          </div>
          <div className="btn-group">
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="insert Ordered List"
              onClick={() => execCommand('insertOrderedList')}
            >
              <InsertOrderedList />
            </button>
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="insert Unordered List"
              onClick={() => execCommand('insertUnorderedList')}
            >
              <InsertUnorderedList />
            </button>
          </div>
          <div className="btn-group">
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="create Link"
              onClick={createLink}
            >
              <CreateLink />
            </button>
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="insert Image"
              onClick={insertImage}
            >
              <InsertImage />
            </button>
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="Insert Table"
              onClick={insertTable}
            >
              <InsertTable />
            </button>
          </div>
          <div className="btn-group">
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="justify Left"
              onClick={() => execCommand('justifyLeft')}
            >
              <JustifyLeft />
            </button>
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="justify Center"
              onClick={() => execCommand('justifyCenter')}
            >
              <JustifyCenter />
            </button>
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="justify Right"
              onClick={() => execCommand('justifyRight')}
            >
              <JustifyRight />
            </button>
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="justify Full"
              onClick={() => execCommand('justifyFull')}
            >
              <JustifyFull />
            </button>
          </div>
          <div className="btn-group">
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="undo"
              onClick={() => execCommand('undo')}
            >
              <Undo />
            </button>
            <button
              disabled={isHtmlView}
              className="btn tooltip tooltip-top"
              data-tooltip="redo"
              onClick={() => execCommand('redo')}
            >
              <Redo />
            </button>
          </div>
          <div className="btn-group">
            <button className="btn tooltip tooltip-top" data-tooltip="toggle Html View" onClick={toggleHtmlView}>
              {isHtmlView ? <Preview /> : <Html />}
            </button>
            <button
              disabled={!content}
              className="btn tooltip tooltip-top"
              data-tooltip="download Content"
              onClick={downloadContent}
            >
              <Download />
            </button>
          </div>
        </div>
        <textarea
          id={`${id}_textarea`}
          className={`${className} textarea ${isHtmlView ? '' : 'disp-n'}`}
          value={content}
          onChange={e => setContent(e.target.value)}
        ></textarea>
        <div
          id={`${id}_content_editable`}
          className={`${className} content-editable ${isHtmlView ? 'disp-n' : ''}`}
          ref={editorRef}
          contentEditable={true}
          onInput={handleChange}
        ></div>
      </div>
      <Prompt
        show={promptObj.showPrompt}
        message={promptObj.promptContent}
        defaultValue={promptObj.defaultPromptValue}
        onSubmit={input =>
          setPromptObj({
            ...promptObj,
            userInput: input,
            showPrompt: false,
          })
        }
        onCancel={() =>
          setPromptObj({
            ...promptObj,
            showPrompt: false,
          })
        }
      />
    </>
  );
};
