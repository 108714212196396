import { decompressFromUTF16 } from 'lz-string';
import { API_URL_ROOT } from './constants/builtFromTop/apiPaths';
import { ICdImages, IWindow } from './types';
import { debounceConsole, toggleLoader } from './utils';

export const API_URL = {
  ...API_URL_ROOT,
  IP_DET: 'https://api64.ipify.org?format=json',
};

export enum API_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export enum API_STATE {
  PENDING = 'pending',
  SENDING = 'sending',
  DONE = 'done',
}

const delayImgRequest = (input: string) => {
  let waitCount = 0;
  const cd = (window as unknown as IWindow).cd;
  return new Promise(resolve => {
    const interval = setInterval(() => {
      waitCount++;
      if (cd.images) {
        clearInterval(interval);
        resolve(cd.images);
      } else {
        if (waitCount > 10) {
          debounceConsole(() => console.log(`Failed to fetch in time (waited for ${waitCount}s): ${input}`), 1000);
        }
      }
    }, 1000);
  });
};

function fromBinary(encoded: string) {
  const binary = atob(encoded);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

export const getApi = async (input: RequestInfo | URL, init?: RequestInit) => {
  const isImagesReq = input === API_URL.IMAGE;
  const cd = (window as unknown as IWindow).cd;
  if (isImagesReq && cd.images) {
    return cd.images;
  } else {
    if (isImagesReq && cd.imageFetching) {
      return await delayImgRequest(input);
    } else {
      cd.imageFetching = true;
      if (init?.method === API_METHOD.POST) {
        if (!init.headers) {
          init.headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          };
        }
      }
      const res = await fetch(input, init);
      let data = await res.json();
      if (isImagesReq) {
        const _d = data.format.startsWith('utf-16') ? fromBinary(data.data) : data.data;
        const _data = data.compress ? decompressFromUTF16(_d) : _d;
        data = Object.entries(JSON.parse(_data || '{}') as ICdImages).reduce((acc, [k, { base64, type, ...rest }]) => {
          acc[k.split('\\').join('/').split('/build/')[1]] = {
            base64: `data:image/${type};base64,${base64}`,
            ...rest,
          };
          return acc;
        }, {});
        cd.images = data;
      }
      console.log({ data });
      toggleLoader();
      cd.imageFetching = false;
      return data;
    }
  }
};
