const skillMeta = {
  HTML5: {
    path: 'html5',
    title: 'The Power of HTML5: Revolutionizing Web Development',
    disc: '',
    logo: '',
  },
  CSS3: {
    path: 'css3',
    title: 'CSS3',
    disc: '',
    logo: '',
  },
  'JavaScript (ES 5, 6+)': {
    path: 'javascript_es_5_6',
    title: 'JavaScript (ES 5, 6+)',
    disc: '',
    logo: '',
  },
  JavaScript: {
    path: 'javascript_es_5_6',
    title: 'JavaScript (ES 5, 6+)',
    disc: '',
    logo: '',
  },
  TypeScript: {
    path: 'typescript',
    title: 'TypeScript',
    disc: '',
    logo: '',
  },
  'Browser Extensions': {
    path: 'browser_extensions',
    title: 'Browser Extensions',
    disc: '',
    logo: '',
  },
  'Chrome Extension': {
    path: 'browser_extensions',
    title: 'Browser Extensions',
    disc: '',
    logo: '',
  },
  'Chrome Developer Tool': {
    path: 'chrome_developer_tool',
    title: 'Chrome Developer Tool',
    disc: '',
    logo: '',
  },
  'Web Components': {
    path: 'web_components',
    title: 'Web Components',
    disc: '',
    logo: '',
  },
  'Twitter Bootstrap': {
    path: 'twitter_bootstrap',
    title: 'Twitter Bootstrap',
    disc: '',
    logo: '',
  },
  Bootstrap: {
    path: 'twitter_bootstrap',
    title: 'Twitter Bootstrap',
    disc: '',
    logo: '',
  },
  Skeleton: {
    path: 'skeleton',
    title: 'Skeleton',
    disc: '',
    logo: '',
  },
  Spectre: {
    path: 'spectre',
    title: 'Spectre',
    disc: '',
    logo: '',
  },
  'Material design': {
    path: 'material_design',
    title: 'Material design',
    disc: '',
    logo: '',
  },
  Less: {
    path: 'less',
    title: 'Less',
    disc: '',
    logo: '',
  },
  lodash: {
    path: 'lodash',
    title: 'lodash',
    disc: '',
    logo: '',
  },
  Sass: {
    path: 'sass',
    title: 'Sass',
    disc: '',
    logo: '',
  },
  PostCSS: {
    path: 'postcss',
    title: 'PostCSS',
    disc: '',
    logo: '',
  },
  'Angular (1.x, 2+, latest)': {
    path: 'angular_1_x_2_latest',
    title: 'Angular (1.x, 2+, latest)',
    disc: '',
    logo: '',
  },
  Angular: {
    path: 'angular_1_x_2_latest',
    title: 'Angular (1.x, 2+, latest)',
    disc: '',
    logo: '',
  },
  'reactJS (15.x, latest)': {
    path: 'reactjs_15_x_latest',
    title: 'reactJS (15.x, latest)',
    disc: '',
    logo: '',
  },
  reactJS: {
    path: 'reactjs_15_x_latest',
    title: 'reactJS (15.x, latest)',
    disc: '',
    logo: '',
  },
  redux: {
    path: 'redux',
    title: 'redux',
    disc: '',
    logo: '',
  },
  VueJS: {
    path: 'vuejs',
    title: 'VueJS',
    disc: '',
    logo: '',
  },
  Hyperapp: {
    path: 'hyperapp',
    title: 'Hyperapp',
    disc: '',
    logo: '',
  },
  Polymer: {
    path: 'polymer',
    title: 'Polymer',
    disc: '',
    logo: '',
  },
  Knockout: {
    path: 'knockout',
    title: 'Knockout',
    disc: '',
    logo: '',
  },
  backbone: {
    path: 'backbone',
    title: 'backbone',
    disc: '',
    logo: '',
  },
  jQuery: {
    path: 'jquery',
    title: 'jQuery',
    disc: '',
    logo: '',
  },
  'Require.js': {
    path: 'require_js',
    title: 'Require.js',
    disc: '',
    logo: '',
  },
  'Underscore.js': {
    path: 'underscore_js',
    title: 'Underscore.js',
    disc: '',
    logo: '',
  },
  'Turn.js': {
    path: 'turn_js',
    title: 'Turn.js',
    disc: '',
    logo: '',
  },
  'High Chart': {
    path: 'high_chart',
    title: 'High Chart',
    disc: '',
    logo: '',
  },
  'Am Chart': {
    path: 'am_chart',
    title: 'Am Chart',
    disc: '',
    logo: '',
  },
  'Zing Chart': {
    path: 'zing_chart',
    title: 'Zing Chart',
    disc: '',
    logo: '',
  },
  'Chart.js': {
    path: 'chart_js',
    title: 'Chart.js',
    disc: '',
    logo: '',
  },
  'Data Structure': {
    path: 'data_structure',
    title: 'Data Structure',
    disc: '',
    logo: '',
  },
  'D3.js': {
    path: 'd3_js',
    title: 'D3.js',
    disc: '',
    logo: '',
  },
  'Google-Chart': {
    path: 'google_chart',
    title: 'Google-Chart',
    disc: '',
    logo: '',
  },
  immutableJS: {
    path: 'immutablejs',
    title: 'immutableJS',
    disc: '',
    logo: '',
  },
  Immutable: {
    path: 'immutablejs',
    title: 'immutableJS',
    disc: '',
    logo: '',
  },
  Grunt: {
    path: 'grunt',
    title: 'Grunt',
    disc: '',
    logo: '',
  },
  Gulp: {
    path: 'gulp',
    title: 'Gulp',
    disc: '',
    logo: '',
  },
  Webpack: {
    path: 'webpack',
    title: 'Webpack',
    disc: '',
    logo: '',
  },
  MVC: {
    path: 'mvc',
    title: 'MVC',
    disc: '',
    logo: '',
  },
  MVVC: {
    path: 'mvvc',
    title: 'MVVC',
    disc: '',
    logo: '',
  },
  'Micro-UI': {
    path: 'micro_ui',
    title: 'Micro-UI',
    disc: '',
    logo: '',
  },
  'Constructor Pattern': {
    path: 'constructor_pattern',
    title: 'Constructor Pattern',
    disc: '',
    logo: '',
  },
  PubSub: {
    path: 'pubsub',
    title: 'PubSub',
    disc: '',
    logo: '',
  },
  Flux: {
    path: 'flux',
    title: 'Flux',
    disc: '',
    logo: '',
  },
  'V+logic': {
    path: 'v_logic',
    title: 'V+logic',
    disc: '',
    logo: '',
  },
  nodeJS: {
    path: 'nodejs',
    title: 'nodeJS',
    disc: '',
    logo: '',
  },
  Express: {
    path: 'express',
    title: 'Express',
    disc: '',
    logo: '',
  },
  GoLang: {
    path: 'golang',
    title: 'GoLang',
    disc: '',
    logo: '',
  },
  Gin: {
    path: 'gin',
    title: 'Gin',
    disc: '',
    logo: '',
  },
  Gorilla: {
    path: 'gorilla',
    title: 'Gorilla',
    disc: '',
    logo: '',
  },
  Java: {
    path: 'java',
    title: 'Java',
    disc: '',
    logo: '',
  },
  JSP: {
    path: 'jsp',
    title: 'JSP',
    disc: '',
    logo: '',
  },
  php: {
    path: 'php',
    title: 'php',
    disc: '',
    logo: '',
  },
  'C++': {
    path: 'c',
    title: 'C++',
    disc: '',
    logo: '',
  },
  'Shell Scripts': {
    path: 'shell_scripts',
    title: 'Shell Scripts',
    disc: '',
    logo: '',
  },
  python: {
    path: 'python',
    title: 'python',
    disc: '',
    logo: '',
  },
  'Apache Shindig': {
    path: 'apache_shindig',
    title: 'Apache Shindig',
    disc: '',
    logo: '',
  },
  Async: {
    path: 'async',
    title: 'Async',
    disc: '',
    logo: '',
  },
  hypernova: {
    path: 'hypernova',
    title: 'hypernova',
    disc: '',
    logo: '',
  },
  handlebar: {
    path: 'handlebar',
    title: 'handlebar',
    disc: '',
    logo: '',
  },
  pug: {
    path: 'pug',
    title: 'pug',
    disc: '',
    logo: '',
  },
  jade: {
    path: 'jade',
    title: 'jade',
    disc: '',
    logo: '',
  },
  'line-by-line': {
    path: 'line_by_line',
    title: 'line-by-line',
    disc: '',
    logo: '',
  },
  'json-schema': {
    path: 'json_schema',
    title: 'json-schema',
    disc: '',
    logo: '',
  },
  Passport: {
    path: 'passport',
    title: 'Passport',
    disc: '',
    logo: '',
  },
  'Database Connectors': {
    path: 'database_connectors',
    title: 'Database Connectors',
    disc: '',
    logo: '',
  },
  Stripe: {
    path: 'stripe',
    title: 'Stripe',
    disc: '',
    logo: '',
  },
  Razorpay: {
    path: 'razorpay',
    title: 'Razorpay',
    disc: '',
    logo: '',
  },
  'All major dataBase': {
    path: 'all_major_database',
    title: 'All major dataBase',
    disc: '',
    logo: '',
  },
  MySQL: {
    path: 'mysql',
    title: 'MySQL',
    disc: '',
    logo: '',
  },
  'ORACLE 10g/11g': {
    path: 'oracle_10g_11g',
    title: 'ORACLE 10g/11g',
    disc: '',
    logo: '',
  },
  MongoDB: {
    path: 'mongodb',
    title: 'MongoDB',
    disc: '',
    logo: '',
  },
  PostgreSQL: {
    path: 'postgresql',
    title: 'PostgreSQL',
    disc: '',
    logo: '',
  },
  'Elastic-Search': {
    path: 'elastic_search',
    title: 'Elastic-Search',
    disc: '',
    logo: '',
  },
  noe4j: {
    path: 'noe4j',
    title: 'noe4j',
    disc: '',
    logo: '',
  },
  Informix: {
    path: 'informix',
    title: 'Informix',
    disc: '',
    logo: '',
  },
  'Clustered Server': {
    path: 'clustered_server',
    title: 'Clustered Server',
    disc: '',
    logo: '',
  },
  'Load Balancer': {
    path: 'load_balancer',
    title: 'Load Balancer',
    disc: '',
    logo: '',
  },
  Docker: {
    path: 'docker',
    title: 'Docker',
    disc: '',
    logo: '',
  },
  Jenkins: {
    path: 'jenkins',
    title: 'Jenkins',
    disc: '',
    logo: '',
  },
  Kubernetes: {
    path: 'kubernetes',
    title: 'Kubernetes',
    disc: '',
    logo: '',
  },
  Kafka: {
    path: 'kafka',
    title: 'Kafka',
    disc: '',
    logo: '',
  },
  Zookeeper: {
    path: 'zookeeper',
    title: 'Zookeeper',
    disc: '',
    logo: '',
  },
  'C-Panel': {
    path: 'c_panel',
    title: 'C-Panel',
    disc: '',
    logo: '',
  },
  'Apache Tomcat': {
    path: 'apache_tomcat',
    title: 'Apache Tomcat',
    disc: '',
    logo: '',
  },
  'Apache Maven': {
    path: 'apache_maven',
    title: 'Apache Maven',
    disc: '',
    logo: '',
  },
  Gradle: {
    path: 'gradle',
    title: 'Gradle',
    disc: '',
    logo: '',
  },
  'DNS management': {
    path: 'dns_management',
    title: 'DNS management',
    disc: '',
    logo: '',
  },
  'Microsoft Azure': {
    path: 'microsoft_azure',
    title: 'Microsoft Azure',
    disc: '',
    logo: '',
  },
  GCP: {
    path: 'gcp',
    title: 'GCP',
    disc: '',
    logo: '',
  },
  'AWS (EC2, Cloud9, IAM, S3, Lambda Function, CloudFront, Route53)': {
    path: 'aws_ec2_cloud9_iam_s3_lambda_function_cloudfront_route53',
    title: 'AWS (EC2, Cloud9, IAM, S3, Lambda Function, CloudFront, Route53)',
    disc: '',
    logo: '',
  },
  AWS: {
    path: 'aws_ec2_cloud9_iam_s3_lambda_function_cloudfront_route53',
    title: 'AWS (EC2, Cloud9, IAM, S3, Lambda Function, CloudFront, Route53)',
    disc: '',
    logo: '',
  },
  Lambda: {
    path: 'aws_ec2_cloud9_iam_s3_lambda_function_cloudfront_route53',
    title: 'AWS (EC2, Cloud9, IAM, S3, Lambda Function, CloudFront, Route53)',
    disc: '',
    logo: '',
  },
  'Cloud + Devops skill': {
    path: 'aws_ec2_cloud9_iam_s3_lambda_function_cloudfront_route53',
    title: 'Cloud + Devops skill',
    disc: '',
    logo: '',
  },
  'People/Project/Growth Management': {
    path: 'engineering_manager',
    title: 'People/Project/Growth Management',
    disc: '',
    logo: '',
  },
  Mocha: {
    path: 'mocha',
    title: 'Mocha',
    disc: '',
    logo: '',
  },
  chaiJS: {
    path: 'chaijs',
    title: 'chaiJS',
    disc: '',
    logo: '',
  },
  shouldJS: {
    path: 'shouldjs',
    title: 'shouldJS',
    disc: '',
    logo: '',
  },
  Assert: {
    path: 'assert',
    title: 'Assert',
    disc: '',
    logo: '',
  },
  expect: {
    path: 'expect',
    title: 'expect',
    disc: '',
    logo: '',
  },
  Mocky: {
    path: 'mocky',
    title: 'Mocky',
    disc: '',
    logo: '',
  },
  'Grunt-cefe-mocha': {
    path: 'grunt_cefe_mocha',
    title: 'Grunt-cefe-mocha',
    disc: '',
    logo: '',
  },
  request: {
    path: 'request',
    title: 'request',
    disc: '',
    logo: '',
  },
  Protractor: {
    path: 'protractor',
    title: 'Protractor',
    disc: '',
    logo: '',
  },
  Jest: {
    path: 'jest',
    title: 'Jest',
    disc: '',
    logo: '',
  },
  Selenium: {
    path: 'selenium',
    title: 'Selenium',
    disc: '',
    logo: '',
  },
  'Robot Framework': {
    path: 'robot_framework',
    title: 'Robot Framework',
    disc: '',
    logo: '',
  },
  Cordova: {
    path: 'cordova',
    title: 'Cordova',
    disc: '',
    logo: '',
  },
  'Cordova Plugin': {
    path: 'cordova_plugin',
    title: 'Cordova Plugin',
    disc: '',
    logo: '',
  },
  'Hybrid App development': {
    path: 'hybrid_app_development',
    title: 'Hybrid App development',
    disc: '',
    logo: '',
  },
  'React Native': {
    path: 'react_native',
    title: 'React Native',
    disc: '',
    logo: '',
  },
  'React Native Plugin Development': {
    path: 'react_native_plugin_development',
    title: 'React Native Plugin Development',
    disc: '',
    logo: '',
  },
  Ionic: {
    path: 'ionic',
    title: 'Ionic',
    disc: '',
    logo: '',
  },
  PhoneGap: {
    path: 'phonegap',
    title: 'PhoneGap',
    disc: '',
    logo: '',
  },
  'Android/IOS native app development': {
    path: 'android_ios_native_app_development',
    title: 'Android/IOS native app development',
    disc: '',
    logo: '',
  },
  WordPress: {
    path: 'wordpress',
    title: 'WordPress',
    disc: '',
    logo: '',
  },
  'Optimize-Press': {
    path: 'optimize_press',
    title: 'Optimize-Press',
    disc: '',
    logo: '',
  },
  'HTML-email': {
    path: 'html_email',
    title: 'HTML-email',
    disc: '',
    logo: '',
  },
  VOS: {
    path: 'vos',
    title: 'VOS',
    disc: '',
    logo: '',
  },
  'VOS Kernel programming': {
    path: 'vos_kernel_programming',
    title: 'VOS Kernel programming',
    disc: '',
    logo: '',
  },
  'VOS Media Micro-Services': {
    path: 'vos_media_micro_services',
    title: 'VOS Media Micro-Services',
    disc: '',
    logo: '',
  },
  'Google API (Translation, GCP, Map etc.)': {
    path: 'google_api_translation_gcp_map_etc',
    title: 'Google API (Translation, GCP, Map etc.)',
    disc: '',
    logo: '',
  },
  'g-map': {
    path: 'google_api_translation_gcp_map_etc',
    title: 'Google API (Translation, GCP, Map etc.)',
    disc: '',
    logo: '',
  },
  IOT: {
    path: 'iot',
    title: 'IOT',
    disc: '',
    logo: '',
  },
  'Socket.io': {
    path: 'socket_io',
    title: 'Socket.io',
    disc: '',
    logo: '',
  },
  WebRTC: {
    path: 'webrtc',
    title: 'WebRTC',
    disc: '',
    logo: '',
  },
  Eclipse: {
    path: 'eclipse',
    title: 'Eclipse',
    disc: '',
    logo: '',
  },
  Webstorm: {
    path: 'webstorm',
    title: 'Webstorm',
    disc: '',
    logo: '',
  },
  PyCharm: {
    path: 'pycharm',
    title: 'PyCharm',
    disc: '',
    logo: '',
  },
  'Android Studio': {
    path: 'android_studio',
    title: 'Android Studio',
    disc: '',
    logo: '',
  },
  XCode: {
    path: 'xcode',
    title: 'XCode',
    disc: '',
    logo: '',
  },
  'Visual Studio': {
    path: 'visual_studio',
    title: 'Visual Studio',
    disc: '',
    logo: '',
  },
  'Tortoise SVN Client': {
    path: 'tortoise_svn_client',
    title: 'Tortoise SVN Client',
    disc: '',
    logo: '',
  },
  Git: {
    path: 'git',
    title: 'Git',
    disc: '',
    logo: '',
  },
  GitHub: {
    path: 'github',
    title: 'GitHub',
    disc: '',
    logo: '',
  },
  Bitbucket: {
    path: 'bitbucket',
    title: 'Bitbucket',
    disc: '',
    logo: '',
  },
  'Big Data': {
    path: 'big_data',
    title: 'Big Data',
    disc: '',
    logo: '',
  },
  GitLab: {
    path: 'gitlab',
    title: 'GitLab',
    disc: '',
    logo: '',
  },
  'Git API/webhooks': {
    path: 'git_api_webhooks',
    title: 'Git API/webhooks',
    disc: '',
    logo: '',
  },
  'Data Analysis': {
    path: 'data_analysis',
    title: 'Data Analysis',
    disc: '',
    logo: '',
  },
  'Data Cleansing': {
    path: 'data_cleansing',
    title: 'Data Cleansing',
    disc: '',
    logo: '',
  },
  'Data Optimization': {
    path: 'data_optimization',
    title: 'Data Optimization',
    disc: '',
    logo: '',
  },
  'Data Processing': {
    path: 'data_processing',
    title: 'Data Processing',
    disc: '',
    logo: '',
  },
  'Data Visualization': {
    path: 'data_visualization',
    title: 'Data Visualization',
    disc: '',
    logo: '',
  },
  Sicence: {
    path: 'sicence',
    title: 'Sicence',
    disc: '',
    logo: '',
  },
  PowerBI: {
    path: 'powerbi',
    title: 'PowerBI',
    disc: '',
    logo: '',
  },
  'Data-Cleaner': {
    path: 'data_cleaner',
    title: 'Data-Cleaner',
    disc: '',
    logo: '',
  },
  Mixpannel: {
    path: 'mixpannel',
    title: 'Mixpannel',
    disc: '',
    logo: '',
  },
  'Data dog': {
    path: 'data_dog',
    title: 'Data dog',
    disc: '',
    logo: '',
  },
  Sentry: {
    path: 'sentry',
    title: 'Sentry',
    disc: '',
    logo: '',
  },
  'Log-Rocket': {
    path: 'log_rocket',
    title: 'Log-Rocket',
    disc: '',
    logo: '',
  },
  'Website Security Analyst': {
    path: 'website_security_analyst',
    title: 'Website Security Analyst',
    disc: '',
    logo: '',
  },
  'Security Analysis': {
    path: 'website_security_analyst',
    title: 'Website Security Analyst',
    disc: '',
    logo: '',
  },
  Cryptography: {
    path: 'cryptography',
    title: 'Cryptography',
    disc: '',
    logo: '',
  },
  'Back-end/Front-end protection': {
    path: 'back_end_front_end_protection',
    title: 'Back-end/Front-end protection',
    disc: '',
    logo: '',
  },
  'Smart-Fox Multiplayer Game Server': {
    path: 'smart_fox_multiplayer_game_server',
    title: 'Smart-Fox Multiplayer Game Server',
    disc: '',
    logo: '',
  },
  'Pot Logic': {
    path: 'pot_logic',
    title: 'Pot Logic',
    disc: '',
    logo: '',
  },
  'Game Logic': {
    path: 'game_logic',
    title: 'Game Logic',
    disc: '',
    logo: '',
  },
  'Score Management': {
    path: 'score_management',
    title: 'Score Management',
    disc: '',
    logo: '',
  },
  JerryScript: {
    path: 'jerryscript',
    title: 'JerryScript',
    disc: '',
    logo: '',
  },
  'Raspberry pi 3': {
    path: 'raspberry_pi_3',
    title: 'Raspberry pi 3',
    disc: '',
    logo: '',
  },
  'wiring-pi': {
    path: 'wiring_pi',
    title: 'wiring-pi',
    disc: '',
    logo: '',
  },
  'Microcontroller/Raspberry Pi connection through Web-Interface': {
    path: 'microcontroller_raspberry_pi_connection_through_web_interface',
    title: 'Microcontroller/Raspberry Pi connection through Web-Interface',
    disc: '',
    logo: '',
  },
  'Agile Methodology': {
    path: 'agile_methodology',
    title: 'Agile Methodology',
    disc: '',
    logo: '',
  },
  Waterfall: {
    path: 'waterfall',
    title: 'Waterfall',
    disc: '',
    logo: '',
  },
  'Build & Fix': {
    path: 'build_fix',
    title: 'Build & Fix',
    disc: '',
    logo: '',
  },
  PWA: {
    path: 'pwa',
    title: 'PWA',
    disc: '',
    logo: '',
  },
  'Atomic Design': {
    path: 'atomic_design',
    title: 'Atomic Design',
    disc: '',
    logo: '',
  },
  MVA: {
    path: 'mva',
    title: 'MVA',
    disc: '',
    logo: '',
  },
  SOLID: {
    path: 'solid',
    title: 'SOLID',
    disc: '',
    logo: '',
  },
  'Cybersecurity Essentials': {
    path: 'cybersecurity_essentials',
    title: 'Cybersecurity Essentials',
    disc: '',
    logo: '',
  },
  'Web Scanning': {
    path: 'web_scanning',
    title: 'Web Scanning',
    disc: '',
    logo: '',
  },
  'Port Scanning': {
    path: 'port_scanning',
    title: 'Port Scanning',
    disc: '',
    logo: '',
  },
  'DNS Analysis': {
    path: 'dns_analysis',
    title: 'DNS Analysis',
    disc: '',
    logo: '',
  },
  'Network Analysis': {
    path: 'network_analysis',
    title: 'Network Analysis',
    disc: '',
    logo: '',
  },
  'Route Rerouting': {
    path: 'route_rerouting',
    title: 'Route Rerouting',
    disc: '',
    logo: '',
  },
  'Proxy Node': {
    path: 'proxy_node',
    title: 'Proxy Node',
    disc: '',
    logo: '',
  },
  'Database Snooping': {
    path: 'database_snooping',
    title: 'Database Snooping',
    disc: '',
    logo: '',
  },
  'OS Backdoors': {
    path: 'os_backdoors',
    title: 'OS Backdoors',
    disc: '',
    logo: '',
  },
  Encryption: {
    path: 'encryption',
    title: 'Encryption',
    disc: '',
    logo: '',
  },
  Decryption: {
    path: 'decryption',
    title: 'Decryption',
    disc: '',
    logo: '',
  },
  'Removing Fingerprint': {
    path: 'removing_fingerprint',
    title: 'Removing Fingerprint',
    disc: '',
    logo: '',
  },
  'Removing Footprint': {
    path: 'removing_footprint',
    title: 'Removing Footprint',
    disc: '',
    logo: '',
  },
  Hana: {
    path: 'hana',
    title: 'Hana',
    disc: '',
    logo: '',
  },
  DB2: {
    path: 'db2',
    title: 'DB2',
    disc: '',
    logo: '',
  },
  'CX-Module': {
    path: 'cx_module',
    title: 'CX-Module',
    disc: '',
    logo: '',
  },
  jsCodeShift: {
    path: 'jscodeshift',
    title: 'jsCodeShift',
    disc: '',
    logo: '',
  },
};

const blogPathTitleMap = Object.values(skillMeta).reduce((acc, { path, title }) => {
  acc[path] = title;
  return acc;
}, {});

export { skillMeta, blogPathTitleMap };
