export const getName = () => {
  let nm = [window.location.host.split('www.').join('').split('.')[0]?.toLowerCase()];
  switch (nm[0]) {
    case 'chaostry':
    case 'trychaos': {
      nm = ['try', 'chaos'];
      break;
    }
    case 'appgoogle': {
      nm = ['app', 'google'];
      break;
    }
    case 'akhileshcoder': {
      nm = ['akhilesh', 'coder'];
      break;
    }
    case 'jaichandal': {
      nm = ['jai', 'chandal'];
      break;
    }
    case 'yourmicsters':
    case 'yourmicster': {
      nm = ['your', 'micsters'];
      break;
    }
    default: {
      nm = ['akhilesh', 'coder'];
      break;
    }
  }
  return {
    name: nm,
    camel: `${nm[0]?.toLowerCase()}${nm[1][0]?.toUpperCase()}${nm[1]?.substring(1).toLowerCase()}`,
  };
};

const splitNchuncks = (array: any[], chunkSize: number) => {
  const ar: any[] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    ar.push(array.slice(i, i + chunkSize));
  }
  return ar;
};

export const getSkills = (isSM?: boolean) => {
  const skills = Array.from(
    new Set([
      'html5',
      'css3',
      'es6',
      'angular_1_x_2_latest',
      'apache_tomcat',
      'aws_ec2_cloud9_iam_s3_lambda_function_cloudfront_route53',
      'azure',
      'big_data',
      'blender',
      'android',
      'chart_js',
      'docker',
      'elastic_search',
      'ethical_hacking',
      'express',
      'gcp',
      'git',
      'gin',
      'golang',
      'gorilla',
      'ios',
      'iot',
      'java',
      'jest',
      'jenkins',
      'kafka',
      'kubernetes',
      'map',
      'mongodb',
      'nodejs',
      'noe4j',
      'oracle_10g_11g',
      'python',
      'postgresql',
      'reactjs_15_x_latest',
      'redux',
      'selenium',
      'socket_io',
      'unity',
      'vuejs',
      'webpack',
      'zookeeper',
      'agile_methodology',
      'cpp',
      'embedded',
      'ionic',
      'typescript',
      'php',
    ]).keys(),
  );
  const cs = 7;
  const cs1 = cs * 6;
  const rest = skills.slice(cs1);
  const mid = rest.length / 2;
  return isSM
    ? skills
    : [
        skills.slice(0, cs),
        skills.slice(cs, cs * 2),
        skills.slice(cs * 2, cs * 3),
        skills.slice(cs * 3, cs * 4),
        skills.slice(cs * 4, cs * 5),
        skills.slice(cs * 5, cs * 6),
        rest.slice(0, mid),
        rest.slice(mid),
      ];
};

export const rond = () => {
  return Math.floor(Math.random() * 1000);
};

export const isPrim = val => ['string', 'number'].includes(typeof val);
export const is1stPrim = val => isPrim(val && val[0]);

export const timeout = (func: () => void, time = 100) => {
  setTimeout(func, time);
};
