import React, { useEffect, useState } from 'react';

const COLOR_LIST = {
  AQUA: 'aqua',
  BLACK: 'black',
  BLUE: 'blue',
  FUCHSIA: 'fuchsia',
  GRAY: 'gray',
  GREEN: 'green',
  LIME: 'lime',
  MAROON: 'maroon',
  NAVY: 'navy',
  OLIVE: 'olive',
  PURPLE: 'purple',
  RED: 'red',
  SILVER: 'silver',
  TEAL: 'teal',
  WHITE: 'white',
  YELLOW: 'yellow',
};

interface ISimpleColorPicker {
  value?: string;
  onChange: (p: string) => void;
}

const SimpleColorPicker = ({ onChange, value }: ISimpleColorPicker) => {
  // Predefined list of colors
  const colors = Object.values(COLOR_LIST);

  // State to keep track of the selected color
  const [selectedColor, setSelectedColor] = useState(value || colors[0]);

  useEffect(() => {
    onChange && onChange(selectedColor);
  }, [selectedColor]);
  return (
    <div className="simple-color-picker">
      <h2>Choose a color:</h2>
      <div className="color-list">
        {colors.map((color, index) => (
          <div
            className={`color-item ${color === selectedColor ? 'selected' : ''}`}
            key={index}
            style={{ backgroundColor: color }}
            onClick={() => setSelectedColor(color)}
          />
        ))}
      </div>
      <div>
        <span>Selected Color: </span>
        <strong>{selectedColor}</strong>
        <span className="color-item-viewer" style={{ backgroundColor: selectedColor }}></span>
      </div>
    </div>
  );
};

export { SimpleColorPicker, COLOR_LIST };
