import { buildFlags, IMAGE_APPROACH_OPTIONS } from './constants/builtFromTop/buildFlags';
import { IWindow } from './types';

const isBase64Approach = buildFlags.IMAGE_APPROACH === IMAGE_APPROACH_OPTIONS.BASE64;

if (!isBase64Approach) {
  (window as unknown as IWindow).cd.images = {};
}
export const getImgSrc = (path: string) => {
  if (isBase64Approach) {
    const images = (window as unknown as IWindow).cd.images;
    if (!images[path]) {
      console.log(path);
    }
    return images[path]?.base64 as unknown as string;
  } else {
    return `${buildFlags.IMAGE_APPROACH}${path.substring(path.lastIndexOf('/') + 1)}`;
  }
};
export const waitToBeDefined = (item: string, cb: (p: any) => void) => {
  console.log('waitToBeDefined: ');
  if (window[item]) {
    cb(window[item]);
  } else {
    setTimeout(() => {
      waitToBeDefined(item, cb);
    }, 500);
  }
};
export const toggleLoader = (show?: boolean, cb?: () => void) => {
  waitToBeDefined('Particles', () => {
    if (!show && !(window as unknown as IWindow).cd.images) {
      setTimeout(() => toggleLoader(show, cb), 500);
    } else {
      const elm = document.getElementsByClassName('main-loader')[0] as HTMLElement;
      if (elm) {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
          elm.style.display = show ? 'block' : 'none';
          cb && cb();
        }, 0);
      }
    }
  });
};

let debounceConsoleTimeout: any;
export const debounceConsole = (func: () => void, time: number | undefined) => {
  clearTimeout(debounceConsoleTimeout);
  debounceConsoleTimeout = setTimeout(func, time);
};
export const getRandomElementFromArray = array => {
  if (array.length === 0) {
    return undefined;
  }
  const randomIndex = Math.floor(Math.random() * array.length);

  return array[randomIndex];
};
