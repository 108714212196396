import { clientRoutes } from './builtFromTop/vidFeed';

const QueryType = {
  '': 'None',
  GENERAL_INQUIRY: 'General Inquiry',
  FEEDBACK: 'Feedback',
  SUGGESTION: 'Suggestion',
  BUG_REPORT: 'Bug Report',
  JOB_OFFER: 'Job Offer',
  PARTNERSHIP_REQUEST: 'Partnership Request',
  TECHNICAL_SUPPORT: 'Technical Support',
  HI: 'Just to say Hi.',
  OTHER: 'Other',
};

const formTypes = {
  TEXT: 'text',
  RADIO: 'radio',
  TEXTAREA: 'textarea',
  SELECT: 'select',
  CHECKBOX: 'Checkbox',
};
const formFieldsInMsg = [
  {
    label: 'Name',
    id: 'ak-form-input-name',
    k: 'name',
    placeholder: 'name',
    type: formTypes.TEXT,
  },
  {
    label: 'Email',
    id: 'ak-form-input-email',
    k: 'email',
    placeholder: 'Email Id',
    type: formTypes.TEXT,
  },
  {
    label: 'Mobile *',
    id: 'ak-form-input-mob',
    k: 'mob',
    placeholder: 'Mobile no.',
    type: formTypes.TEXT,
  },
  {
    label: 'Gender',
    k: 'gender',
    type: formTypes.RADIO,
    opt: [
      { l: 'Male', k: 'm' },
      { l: 'Female', k: 'f' },
      { l: 'Other', k: 'o' },
      { l: 'Prefer not to say', k: 'n' },
    ],
  },
  {
    label: 'Message *',
    id: 'ak-form-input-message',
    k: 'message',
    rows: 3,
    placeholder: 'Your queries or messages',
    type: formTypes.TEXTAREA,
  },
  {
    label: 'Send me emails with news and tips',
    k: 'subscribed',
    checkFlags: {
      t: 'yes',
      f: 'no',
    },
    type: formTypes.CHECKBOX,
  },
  {
    label: 'Message Type',
    k: 'msg_type',
    id: 'ak-form-input-msg-type',
    opt: Object.entries(QueryType).map(([k, v]) => ({ l: v, k })),
    type: formTypes.SELECT,
  },
];

const getPreviousPages = () => {
  let PREVIOUS_PAGES = Object.values(clientRoutes).map(({ k: url, title }) => ({ url, title }));
  try {
    let history: any = localStorage.getItem('PREVIOUS_PAGES');
    if (history) {
      history = history ? JSON.parse(history) : [];
      history.forEach(({ url }) => {
        PREVIOUS_PAGES = PREVIOUS_PAGES.filter(i => i.url !== url);
      });
      PREVIOUS_PAGES = [{ url: '', title: 'None' }, ...history, ...PREVIOUS_PAGES].map(i => {
        const splitTitle = i.title.split(':');
        i.title = i.url.startsWith('/blog/') ? i.title : splitTitle[1] || i.title;
        return i;
      });
    }
  } catch (e) {}
  return (
    Array.isArray(PREVIOUS_PAGES) &&
    PREVIOUS_PAGES.length && {
      label: 'Select page context',
      k: 'page_context',
      id: 'ak-form-input-previous-pages',
      opt: PREVIOUS_PAGES.map(({ url, title }) => ({ l: title, k: url })),
      type: formTypes.SELECT,
    }
  );
};

export { formTypes, formFieldsInMsg, getPreviousPages };
