import { AddToCalendarButton } from 'add-to-calendar-button-react';
import React from 'react';
import { ImgComp } from '../Img';
import { Social } from '../Social';

export const DrinkWithMe = () => {
  const calEvent = {
    name: 'Drink with Me - A segment by @tryChaos',
    options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'] as any,
    location: 'zoom',
    startDate: '2023-05-27',
    endDate: '2023-05-28',
    startTime: '22:00',
    endTime: '02:30',
    timeZone: 'Asia/Kolkata',
  };
  return (
    <>
      <div className="App flex-col dwm dwm-lg">
        <div className="parallax">
          <div className="parallax-top-left" tabIndex={1}></div>
          <div className="parallax-top-right" tabIndex={2}></div>
          <div className="parallax-bottom-left" tabIndex={3}></div>
          <div className="parallax-bottom-right" tabIndex={4}></div>
          <div className="parallax-content">
            <div className="parallax-front">
              <h2>@tryChaos</h2>
            </div>
            <div className="parallax-back">
              <ImgComp alt="dwmPoster" src="icon/dwmPoster.png" className="img-responsive rounded dwm-img" />
            </div>
          </div>
        </div>
        <div className="timeline">
          <div className="timeline-item" id="timeline-buy">
            <div className="timeline-left">
              <a className="timeline-icon" href="#timeline-buy">
                {' '}
              </a>
            </div>
            <div className="timeline-content">
              <h1>Buy our ticket for:</h1>
            </div>
          </div>

          <div className="timeline-item" id="timeline-bms">
            <div className="timeline-left">
              <a className="timeline-icon icon-lg" href="#timeline-bms">
                <i className="icon icon-check"></i>
              </a>
            </div>
            <div className="timeline-content">
              <h2>Drink with Me - A segment by @tryChaos</h2>

              <a
                href="https://in.bookmyshow.com/events/drink-with-me-a-segment-by-akhilesh-kumar/ET00360019?webview=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://in.bookmyshow.com/events/drink-with-me-a-segment-by-akhilesh-kumar/ET00360019?webview=true
              </a>
            </div>
          </div>

          <div className="timeline-item" id="timeline-location">
            <div className="timeline-left">
              <a className="timeline-icon icon-lg" href="#timeline-location">
                <i className="icon icon-check"></i>
              </a>
            </div>
            <div className="timeline-content">
              <h5>
                <b>Time:</b> Sat 27 May 2023, @10pm
              </h5>
              <h5>
                <b>Location:</b> online ZOOM call, LINKs will be delivered at your doorstep...
              </h5>
              <p>
                <AddToCalendarButton {...calEvent} />
              </p>
            </div>
          </div>

          <div className="timeline-item" id="timeline-gifts">
            <div className="timeline-left">
              <a className="timeline-icon icon-lg" href="#timeline-gifts">
                <i className="icon icon-check"></i>
              </a>
            </div>
            <div className="timeline-content">Gifts and other food Vouchers are on the House.... for the attendees</div>
          </div>
          <div className="timeline-item" id="timeline-disclaimer">
            <div className="timeline-left">
              <a className="timeline-icon icon-lg" href="#timeline-disclaimer">
                <i className="icon icon-check"></i>
              </a>
            </div>
            <div className="timeline-content">
              <p>
                <b>Disclaimer:</b>
                We don&apos;t promote any kind of Drinking or smoking activity. Its just a volunteering Event to Have
                fun with the other People. So Do Join the Show with your Consent And Enjoy...
              </p>

              <b>*Drinking/Smoking is injurious to Health*</b>
            </div>
          </div>
          <div className="timeline-item" id="timeline-social">
            <div className="timeline-left">
              <a className="timeline-icon icon-lg" href="#timeline-social">
                <i className="icon icon-check"></i>
              </a>
            </div>
            <div className="timeline-content">
              <Social />
            </div>
          </div>
        </div>
      </div>
      <div className="App dwm dwm-sm">
        <div className="parallax">
          <div className="parallax-top-left" tabIndex={1}></div>
          <div className="parallax-top-right" tabIndex={2}></div>
          <div className="parallax-bottom-left" tabIndex={3}></div>
          <div className="parallax-bottom-right" tabIndex={4}></div>
          <div className="parallax-content">
            <div className="parallax-front">
              <h2>@tryChaos</h2>
            </div>
            <div className="parallax-back">
              <ImgComp alt="dwmPoster" src="icon/dwmPoster.png" className="img-responsive rounded" />
            </div>
          </div>
        </div>
        <p className="h4">Buy our ticket for:</p>
        <p className="h3">Drink with Me - A segment by @tryChaos</p>
        <p>
          <a
            className="btn btn-success"
            href="https://in.bookmyshow.com/events/drink-with-me-a-segment-by-akhilesh-kumar/ET00360019?webview=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy on BookMyShow
          </a>
        </p>
        <p className="h5">
          <b>Time:</b> Sat 27 May 2023, @10pm
        </p>
        <p>
          <b>Location:</b> online ZOOM call, LINKs will be delivered at your doorstep...
        </p>
        <p>
          <AddToCalendarButton {...calEvent} />
        </p>
        <p>Gifts and other food Vouchers are on the House.... for the attendees</p>
        <p>
          <b>Disclaimer:</b>
          We don&apos;t promote any kind of Drinking or smoking activity. Its just a volunteering Event to Have fun with
          the other People. So Do Join the Show with your Consent And Enjoy...
        </p>
        <p>
          <b>*Drinking/Smoking is injurious to Health*</b>
        </p>
        <Social />
      </div>
    </>
  );
};
