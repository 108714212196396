import React from 'react';
import { Messege } from '../Messege';
import { Social } from '../Social';
import { ResumePages } from './Resume';

interface IResumeSocial {
  type?: ResumePages;
}

export const ResumeSocial = ({ type }: IResumeSocial) => {
  return (
    <div className="social-page">
      <Messege type={type} />
      <Social />
    </div>
  );
};
