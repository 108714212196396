import React, { useState } from 'react';

interface ICheckbox {
  label: string;
  onChange: (d: boolean) => void;
}
export const Checkbox = ({ label, onChange }: ICheckbox) => {
  const [checked, setChecked] = useState(false);
  const handleClick = () => {
    const nuState = !checked;
    setChecked(nuState);
    onChange(nuState);
  };
  return (
    <div className={`form-switch ${checked ? 'checked-ck' : ''}`} onClick={handleClick}>
      <i className="form-icon"></i> {label}
    </div>
  );
};
