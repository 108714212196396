import React, { useEffect, useRef, useState } from 'react';

import { ToastContainer } from 'react-toastify';
import { waitToBeDefined } from '../../utils';
import { MenuIcon } from '../common/menuIcon';
import { Nav } from '../Nav/Nav';
import { CoderLogo } from './CoderLogo';
import { ResumeDetails } from './Details';
import { DrinkWithMe } from './drinkWithMe';
import { ResumeSocial } from './ResumeSocial';
import { Wip } from './Wip';

export enum ResumePages {
  HOME = 'HOME',
  DETAILS = 'DETAILS',
  SOCIAL = 'SOCIAL',
  DWM = 'DWM',
  WIP = 'WIP',
  CHAT = 'CHAT',
}

interface IResume {
  type?: ResumePages;
}

const getPages = type => {
  switch (type) {
    case ResumePages.HOME:
      return <CoderLogo />;
    case ResumePages.DETAILS:
      return <ResumeDetails />;
    case ResumePages.SOCIAL:
      return <ResumeSocial type={ResumePages.SOCIAL} />;
    case ResumePages.CHAT:
      return <ResumeSocial type={ResumePages.CHAT} />;
    case ResumePages.DWM:
      return <DrinkWithMe />;
    case ResumePages.WIP:
      return <Wip />;
    default:
      return <CoderLogo />;
  }
};

export const Resume = ({ type = ResumePages.HOME }: IResume) => {
  const [isNavClosed, toggleNavOpen] = useState(true);
  const elementRef = useRef<HTMLDivElement>(null);
  const handleToggle = (passedState?: boolean) => {
    toggleNavOpen(typeof passedState === 'boolean' ? passedState : !isNavClosed);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  };

  const clickListner = ev => {
    const t = ev.target;
    if (t.classList.contains('nav-anchor') || t.closest('.nav-anchor')) {
      handleToggle(true);
    }
  };

  useEffect(() => {
    waitToBeDefined('Particles', p => {
      p.init({
        selector: '.l-nav-bg',
        connectParticles: false,
        color: ['#6caeef', '#faebd7', '#03dac6', '#ff0266'],
      });
    });
    elementRef.current?.addEventListener('click', clickListner, true);
    return () => {
      elementRef.current?.removeEventListener('click', clickListner);
    };
  }, []);
  return (
    <div className="App">
      <div className={`canvas-bg hidden-lg ${isNavClosed ? 'opened-nav' : ''}`}></div>
      <canvas className={`l-nav-bg  ${isNavClosed ? 'opened-nav' : ''}`} />
      <div className={`l-nav ${isNavClosed ? 'opened-nav' : ''}`} ref={elementRef}>
        <Nav className="nav-anchor" />
        <MenuIcon
          className={`hidden-lg toggle-nav-drawer ${isNavClosed ? 'opened-nav' : ''}`}
          onClick={handleToggle}
          closedState={isNavClosed}
        />
      </div>
      <div className="page">
        <div className="page-area">{getPages(type)}</div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Resume;
