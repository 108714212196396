// Prompt.js
import React, { Fragment, useEffect, useState } from 'react';
import { IReactElm } from '../typeUtil';

export type IMsg = string | IReactElm | null;

interface IPrompt {
  show?: boolean;
  message?: IMsg[];
  defaultValue?: string[];
  onSubmit: (p?: string[]) => void;
  onCancel?: () => void;
}

const Prompt = ({ show, message: MessageComp, defaultValue, onSubmit, onCancel }: IPrompt) => {
  const Msg = Array.isArray(MessageComp) ? MessageComp : [MessageComp || null];
  const defaultVals = new Array(Msg.length).fill('');
  const [inputValue, setInputValue] = useState([...defaultVals]);

  useEffect(() => {
    if (show) {
      setInputValue(defaultValue || [...defaultVals]);
    }
  }, [show, defaultValue]);

  if (!show) {
    return null;
  }

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(inputValue || '');
  };
  const getMsgComp = () => {
    const onChange = (val: string, ind: number) => {
      const tmp = [...inputValue];
      tmp[ind] = val;
      setInputValue(tmp);
    };
    const getElm = (M: IMsg, ind: number) => {
      // @ts-ignore MessageComp
      return M && <M key={ind} onChange={(val: string) => onChange(val, ind)} value={inputValue[ind]} />;
    };
    return Msg.map((M, ind) =>
      typeof M === 'string' ? (
        <Fragment key={ind}>
          <p>{M}</p>
          <input
            className="form-input"
            type="text"
            value={inputValue[ind]}
            onChange={e => onChange(e.target.value, ind)}
            autoFocus
          />
        </Fragment>
      ) : (
        getElm(M, ind)
      ),
    );
  };

  return (
    <>
      <div className="prompt-overlay" onClick={onCancel}></div>
      <div className="prompt-body">
        {getMsgComp()}
        <div className="btn-group">
          <button className="btn btn-success" onClick={handleSubmit}>
            Submit
          </button>
          <button className="btn" type="button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export { Prompt };
