import React, { Fragment, useState } from 'react';
import { resData } from '../../utils';
import { blogNamePathMap } from '../../utils/constants/builtFromTop/blogNamePathMap';
import { skillMeta } from '../../utils/constants/skillMeta';
import { StaticPdf } from '../GenericPdfDownloader';
import { ImgComp } from '../Img';

import { is1stPrim, isPrim, rond } from '../util';

const getHref = (txt: string) => {
  const p = blogNamePathMap[skillMeta[txt].path];
  const nm = skillMeta[txt].path;
  if (!p || !nm) {
    console.log(JSON.stringify({ p, nm, txt }));
  }
  return `${p}/${nm}.html`;
};

const getClassNm = str =>
  `${str}`
    .split(/[^a-zA-Z0-9]/g)
    .join('-')
    .toLowerCase();

const parseText = txt => {
  txt = `${txt}`.trim();
  if (txt.startsWith('http')) {
    return (
      <a href={txt} target="_blank" rel="noopener noreferrer">
        {txt}
      </a>
    );
  }
  if (txt.startsWith('+91')) {
    return (
      <a href={`tel:${txt}`} target="_blank" rel="noopener noreferrer">
        {txt}
      </a>
    );
  }
  if (txt.includes('@gmail.com')) {
    return (
      <a href={`mailto:${txt}`} target="_blank" rel="noopener noreferrer">
        {txt}
      </a>
    );
  }
  if (skillMeta[txt]) {
    return (
      <a href={getHref(txt)} target="_blank" rel="noopener noreferrer">
        {txt}
      </a>
    );
  }
  return txt;
};

const iterateObj = (obj: any, ky?: number) => {
  const rest = {} as any;
  ky || ky === 0 || (rest.key = ky);
  if (isPrim(obj)) {
    return obj ? (
      <li className={`res-span`} {...rest} key={`${ky}_${rond()}`}>
        {parseText(obj)}
      </li>
    ) : null;
  } else if (Array.isArray(obj)) {
    return obj.map((o, ind) => iterateObj(o, ind));
  } else if (typeof obj === 'object') {
    return Object.entries(obj).map(([k, v], ind) => {
      return (
        <ul key={`${ind}_${ky}`} className={getClassNm(k)}>
          <li className={`ul-label ${is1stPrim(v) ? 'next-res-span' : ''}`}>{k}</li>
          {iterateObj(v)}
        </ul>
      );
    });
  }
};

const iterateSec = obj => {
  return Object.entries(obj).map(([k, v], ind) => {
    const counter = ind + 3;
    return (
      <Fragment key={counter}>
        <input type="checkbox" id={`sec-${counter}-show-hide`} className="show-hide" />
        <input type="checkbox" id={`sec-${counter}-resize`} className="resize" />
        <input type="checkbox" id={`sec-${counter}-delete`} className="delete" />
        <div className={`res-sec sec-${counter} ${getClassNm(k)}`} key={ind}>
          <div className="sec-label">
            <span>{k}</span>
            <span className="sec-actions">
              <label htmlFor={`sec-${counter}-show-hide`}>
                <i className="icon icon-lg icon-minus"></i>
                <i className="icon icon-lg icon-plus"></i>
              </label>
              <label htmlFor={`sec-${counter}-resize`}>
                <i className="icon icon-lg icon-copy"></i>
                <i className="icon icon-lg icon-resize-vert"></i>
              </label>
              <label htmlFor={`sec-${counter}-delete`}>
                <i className="icon icon-lg icon-cross"></i>
              </label>
            </span>
          </div>
          <div className="sec-det">{iterateObj(v)}</div>
        </div>
      </Fragment>
    );
  });
};
const resumeID = 'resume-page';
export const ResumeDetails = () => {
  const [res] = useState(resData);
  const { name, eMail, Phone, Address, Summary, ...rest } = res;
  return (
    <>
      <StaticPdf className="btn btn-sm hidden-lg" />
      <div className="resume" id={resumeID}>
        <div className="res-sec sec-1">
          <div className="sec-1_1">
            <ImgComp alt="profile" src="icon/pp2.png" />
          </div>
          <div className="sec-1_2">
            <h4>
              <a href="https://akhileshcoder.com">Akhilesh Kumar</a>
            </h4>
            <h6>
              <b>E-Mail:</b>
              {parseText(eMail)}
            </h6>
            <h6>
              <b>Phone:</b>
              {parseText(Phone)}
            </h6>
            <h6>
              <b>Address:</b>
              {Address}
            </h6>
            <h6 className="hidden-sm">
              <b>Download:</b>
              <StaticPdf className="btn btn-sm btn-success" label={['Detailed Resume', 'Concise Resume']} />
            </h6>
          </div>
        </div>
        <div className="res-sec sec-2">{Summary}</div>
        {iterateSec(rest)}
      </div>
    </>
  );
};
