import React from 'react';
import { Link } from 'react-router-dom';
import { getName } from './util';

interface IIconLogo {
  className?: string;
}

const IconLogo = ({ className, ...rest }: IIconLogo) => {
  const nm = getName();
  return (
    <Link to="/" className={`d-inline icon-logo ${className || ''}`} {...rest}>
      <span>{nm.name[0]}</span>
      <span>{'{'}</span>
      <span>{nm.name[1]}</span>
      <span>;</span>
      <span>{'}'}</span>
    </Link>
  );
};

export { IconLogo };
