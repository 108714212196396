export const blogNamePathMap = {
  "11dimen": "/blog/non_technical/theory",
  "3n_plus_1": "/blog/non_technical/theory",
  "a_comprehensive_internet_guide": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "a_guide_to_online_services_platforms_and_trends": "/blog/non_technical/chaos/topics/online_plateforms",
  "achintya_bheda_abheda_darshan": "/blog/non_technical/darshans",
  "advaita_darshan": "/blog/non_technical/darshans",
  "agile_methodology": "/blog/technical/process",
  "agile_methodology_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "aham_brahmasmi": "/blog/non_technical/mantra",
  "all_major_database": "/blog/technical/db",
  "am_chart": "/blog/technical/frameworks_and_libraries/miscellaneous",
  "andaman_and_nicobar_islands": "/blog/non_technical/places_to_visit/southern_india",
  "andhra_pradesh": "/blog/non_technical/places_to_visit/southern_india",
  "android": "/blog/technical/plateform",
  "android_ios_native_app_development": "/blog/technical/plateform",
  "android_studio": "/blog/technical/tools",
  "angular_1_x_2_latest": "/blog/technical/frameworks_and_libraries/javascript",
  "angular_1_x_2_latest_qna": "/blog/technical/interview_questions_and_answers/generic/web_development",
  "answers_to_popular_internet_questions": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "apache_maven": "/blog/technical/util",
  "apache_shindig": "/blog/technical/util",
  "apache_tomcat": "/blog/technical/tools",
  "arunachal_pradesh": "/blog/non_technical/places_to_visit/north_eastern_india",
  "assam": "/blog/non_technical/places_to_visit/north_eastern_india",
  "assert": "/blog/technical/frameworks_and_libraries/miscellaneous",
  "async": "/blog/technical/frameworks_and_libraries/miscellaneous",
  "atal_bihari_vajpayee": "/blog/non_technical/characters/real",
  "atomic_design": "/blog/technical/process",
  "automation_testing": "/blog/technical/process",
  "automation_testing_qna": "/blog/technical/interview_questions_and_answers/generic/testing_and_qa",
  "aws_ec2_cloud9_iam_s3_lambda_function_cloudfront_route53": "/blog/technical/cloud",
  "aws_ec2_cloud9_iam_s3_lambda_function_cloudfront_route53_qna": "/blog/technical/interview_questions_and_answers/generic/cloud",
  "aws_lambda": "/blog/technical/cloud",
  "aws_lambda_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "azure": "/blog/technical/cloud",
  "back_end_front_end_protection": "/blog/technical/security",
  "backbone": "/blog/technical/frameworks_and_libraries/javascript",
  "batman_characters": "/blog/non_technical/characters/fictional",
  "bhagat_singh": "/blog/non_technical/characters/real",
  "bhagwat_geeta": "/blog/non_technical/spiritual-science",
  "big_data": "/blog/technical/data",
  "bihar": "/blog/non_technical/places_to_visit/eastern_india",
  "bihar_history": "/blog/non_technical/bihar",
  "bihar_jurney": "/blog/non_technical/bihar",
  "bihar_n_science": "/blog/non_technical/bihar",
  "bihar_unseen": "/blog/non_technical/bihar",
  "bitbucket": "/blog/technical/tools",
  "blender": "/blog/technical/tools",
  "browser_extensions": "/blog/technical/web",
  "build_fix": "/blog/technical/process",
  "c": "/blog/technical/language",
  "c_panel": "/blog/technical/util",
  "c_qna": "/blog/technical/interview_questions_and_answers/generic/programming_languages",
  "career_counseling": "/blog/services",
  "chacha_chaudhary": "/blog/non_technical/characters/fictional",
  "chaijs": "/blog/technical/frameworks_and_libraries/testing",
  "chandigarh": "/blog/non_technical/places_to_visit/northern_india",
  "chandra_shekhar_azad": "/blog/non_technical/characters/real",
  "chaos_theory": "/blog/non_technical/theory",
  "chart_js": "/blog/technical/frameworks_and_libraries/charting_and_visualization",
  "chhattisgarh": "/blog/non_technical/places_to_visit/central_india",
  "chrome_developer_tool": "/blog/technical/web",
  "ci_cd": "/blog/technical/process",
  "ci_cd_qna": "/blog/technical/interview_questions_and_answers/generic/devops",
  "clustered_server": "/blog/technical/cloud",
  "comprehensive_guide_to_common_queries": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "comprehensive_how_to_guide_answers_to_everyday_questions": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "constructor_pattern": "/blog/technical/process",
  "contemporary_online_platforms_and_trends": "/blog/non_technical/chaos/topics/online_plateforms",
  "cordova": "/blog/technical/plateform",
  "cordova_plugin": "/blog/technical/plateform",
  "cpp": "/blog/technical/language",
  "cpp_qna": "/blog/technical/interview_questions_and_answers/generic/programming_languages",
  "crack_angular": "/blog/technical/interview_questions_and_answers/must-know",
  "crack_angularjs": "/blog/technical/interview_questions_and_answers/must-know",
  "crack_css": "/blog/technical/interview_questions_and_answers/must-know",
  "crack_html": "/blog/technical/interview_questions_and_answers/must-know",
  "crack_js": "/blog/technical/interview_questions_and_answers/must-know",
  "crack_mix": "/blog/technical/interview_questions_and_answers/must-know",
  "crack_react": "/blog/technical/interview_questions_and_answers/must-know",
  "cryptography": "/blog/technical/security",
  "css3": "/blog/technical/web",
  "css3_qna": "/blog/technical/interview_questions_and_answers/generic/web_development",
  "current_online_platforms_and_personalities": "/blog/non_technical/chaos/topics/online_plateforms",
  "current_trends_and_insights_in_online_dating": "/blog/non_technical/chaos/questionnaire/dating",
  "cx_module": "/blog/technical/util",
  "cybersecurity_essentials": "/blog/technical/security",
  "cybersecurity_essentials_qna": "/blog/technical/interview_questions_and_answers/generic/security",
  "d3_js": "/blog/technical/frameworks_and_libraries/charting_and_visualization",
  "dadra_and_nagar_haveli": "/blog/non_technical/places_to_visit/western_india",
  "daman_and_diu": "/blog/non_technical/places_to_visit/western_india",
  "data_analysis": "/blog/technical/data",
  "data_cleaner": "/blog/technical/data",
  "data_cleansing": "/blog/technical/data",
  "data_dog": "/blog/technical/util",
  "data_optimization": "/blog/technical/data",
  "data_processing": "/blog/technical/data",
  "data_structure": "/blog/technical/data",
  "data_structure_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "data_visualization": "/blog/technical/data",
  "database_connectors": "/blog/technical/db",
  "database_snooping": "/blog/technical/security",
  "dating": "/blog/non_technical/chaos/topics/dating",
  "db2": "/blog/technical/db",
  "db2_qna": "/blog/technical/interview_questions_and_answers/generic/db",
  "decryption": "/blog/technical/security",
  "delhi": "/blog/non_technical/places_to_visit/northern_india",
  "design_patterns": "/blog/technical/process",
  "design_patterns_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "devops": "/blog/technical/roles_and_position",
  "devops_qna": "/blog/technical/interview_questions_and_answers/generic/devops",
  "dhootha_explained_in_hindi": "/blog/non_technical/movie_story/horror",
  "digital_media_and_sporting_goods": "/blog/non_technical/chaos/topics/digital_edge",
  "digital_media_current_events_and_lifestyle_trends": "/blog/non_technical/chaos/topics/digital_edge",
  "digital_services_and_cultural_trends_comprehensive_exploration": "/blog/non_technical/chaos/topics/digital_edge",
  "digital_services_brands_and_personalities": "/blog/non_technical/chaos/topics/digital_edge",
  "digital_trends": "/blog/non_technical/chaos/topics/digital_edge",
  "digital_trends_and_global_influences": "/blog/non_technical/chaos/topics/digital_edge",
  "discovering_india": "/blog/non_technical/india",
  "diverse_spectrum_of_online_dating": "/blog/non_technical/chaos/questionnaire/dating",
  "diverse_world_of_online_dating": "/blog/non_technical/chaos/questionnaire/dating",
  "dns_analysis": "/blog/technical/security",
  "dns_management": "/blog/technical/security",
  "docker": "/blog/technical/plateform",
  "docker_qna": "/blog/technical/interview_questions_and_answers/generic/cloud",
  "doga": "/blog/non_technical/characters/fictional",
  "dr_apj_abdul_kalam": "/blog/non_technical/characters/real",
  "dual_path_of_dharma": "/blog/non_technical/mantra",
  "dvaita_darshan": "/blog/non_technical/darshans",
  "dvaitadvaita_darshan": "/blog/non_technical/darshans",
  "dynamic_world_of_online_dating": "/blog/non_technical/chaos/questionnaire/dating",
  "eclipse": "/blog/technical/tools",
  "elastic_search": "/blog/technical/db",
  "elastic_search_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "embedded": "/blog/technical/embedded_system",
  "embracing_the_divine": "/blog/non_technical/mantra",
  "emotion_purpose": "/blog/non_technical/concept",
  "emotions": "/blog/non_technical/concept",
  "encryption": "/blog/technical/security",
  "engineering_manager": "/blog/technical/roles_and_position",
  "engineering_manager_qna": "/blog/technical/interview_questions_and_answers/generic/job_roles",
  "es6": "/blog/technical/web",
  "es6_qna": "/blog/technical/interview_questions_and_answers/generic/programming_languages",
  "eternal_promise_of_divine_intervention": "/blog/non_technical/mantra",
  "ethical_hacking": "/blog/technical/security",
  "evolving_world_of_online_dating": "/blog/non_technical/chaos/questionnaire/dating",
  "expect": "/blog/technical/frameworks_and_libraries/testing",
  "exploring_popular_topics_from_tech_to_entertainment": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "exploring_the_world_of_internet_gaming": "/blog/non_technical/chaos/questionnaire/gaming",
  "express": "/blog/technical/frameworks_and_libraries/server_side",
  "flux": "/blog/technical/frameworks_and_libraries/javascript",
  "free_websites_diverse_options_and_trends": "/blog/non_technical/chaos/questionnaire/dating",
  "from_antivirus_to_marketing": "/blog/non_technical/chaos/questionnaire/technology",
  "from_free_sites_to_matchmaking_services": "/blog/non_technical/chaos/questionnaire/dating",
  "from_sims_to_online_sites_and_apps": "/blog/non_technical/chaos/questionnaire/dating",
  "from_sports_to_technology": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "front_end_developer": "/blog/technical/roles_and_position",
  "front_end_developer_qna": "/blog/technical/interview_questions_and_answers/generic/job_roles",
  "full_stack_developer": "/blog/technical/roles_and_position",
  "full_stack_developer_qna": "/blog/technical/interview_questions_and_answers/generic/job_roles",
  "functional_testing": "/blog/technical/concept",
  "game_logic": "/blog/technical/util",
  "garuda_purana": "/blog/non_technical/spiritual-science",
  "gcp": "/blog/technical/cloud",
  "gcp_qna": "/blog/technical/interview_questions_and_answers/generic/cloud",
  "gin": "/blog/technical/frameworks_and_libraries/server_side",
  "git": "/blog/technical/tools",
  "git_api_webhooks": "/blog/technical/util",
  "git_qna": "/blog/technical/interview_questions_and_answers/generic/devops",
  "github": "/blog/technical/tools",
  "gitlab": "/blog/technical/tools",
  "global_landscape_of_tech_careers_salaries_opportunities_and_skills": "/blog/non_technical/chaos/questionnaire/technology",
  "goa": "/blog/non_technical/places_to_visit/western_india",
  "golang": "/blog/technical/language",
  "golang_qna": "/blog/technical/interview_questions_and_answers/generic/programming_languages",
  "google_api_translation_gcp_map_etc": "/blog/technical/web",
  "google_chart": "/blog/technical/frameworks_and_libraries/charting_and_visualization",
  "gorilla": "/blog/technical/frameworks_and_libraries/server_side",
  "gradle": "/blog/technical/frameworks_and_libraries/task_runners_and_build_tools",
  "grunt": "/blog/technical/frameworks_and_libraries/task_runners_and_build_tools",
  "grunt_cefe_mocha": "/blog/technical/frameworks_and_libraries/task_runners_and_build_tools",
  "gujarat": "/blog/non_technical/places_to_visit/western_india",
  "gulp": "/blog/technical/frameworks_and_libraries/task_runners_and_build_tools",
  "hana": "/blog/technical/db",
  "handlebar": "/blog/technical/frameworks_and_libraries/template_engines",
  "harley_quinn": "/blog/non_technical/characters/fictional",
  "harmony_between_hinduism_and_buddhism": "/blog/non_technical/topics",
  "harmony_between_hinduism_and_judaism": "/blog/non_technical/topics",
  "harmony_of_hinduism_and_sikhism": "/blog/non_technical/topics",
  "harry_potter_and_voldemort": "/blog/non_technical/characters/fictional",
  "haryana": "/blog/non_technical/places_to_visit/northern_india",
  "healthy_life": "/blog/non_technical/concept",
  "high_chart": "/blog/technical/frameworks_and_libraries/charting_and_visualization",
  "himachal_pradesh": "/blog/non_technical/places_to_visit/northern_india",
  "hindu_science": "/blog/non_technical/spiritual-science",
  "hinduism_in_world_fabric": "/blog/non_technical/spiritual-science",
  "hindus_and_sikhs_in_mughal_dynasty": "/blog/non_technical/topics",
  "holographic_univ": "/blog/non_technical/theory",
  "horcrux": "/blog/non_technical/mix",
  "html5": "/blog/technical/web",
  "html5_qna": "/blog/technical/interview_questions_and_answers/generic/web_development",
  "html_email": "/blog/technical/web",
  "hybrid_app_development": "/blog/technical/plateform",
  "hyperapp": "/blog/technical/frameworks_and_libraries/javascript",
  "hypernova": "/blog/technical/frameworks_and_libraries/javascript",
  "immutablejs": "/blog/technical/frameworks_and_libraries/miscellaneous",
  "index": "/blog",
  "india_in_glance": "/blog/non_technical/india",
  "india_into_world_fabric": "/blog/non_technical/india",
  "india_n_science": "/blog/non_technical/india",
  "indira_gandhi": "/blog/non_technical/characters/real",
  "informix": "/blog/technical/db",
  "insights_and_practical_information": "/blog/non_technical/chaos/topics/online_plateforms",
  "insights_into_popular_online_platforms_and_services": "/blog/non_technical/chaos/topics/online_plateforms",
  "internet_games": "/blog/non_technical/chaos/topics/gaming",
  "ionic": "/blog/technical/plateform",
  "ios": "/blog/technical/plateform",
  "iot": "/blog/technical/embedded_system",
  "iot_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "jade": "/blog/technical/frameworks_and_libraries/template_engines",
  "jammu_and_kashmir": "/blog/non_technical/places_to_visit/northern_india",
  "java": "/blog/technical/language",
  "java_qna": "/blog/technical/interview_questions_and_answers/generic/programming_languages",
  "javascript_es_5_6": "/blog/technical/language",
  "javascript_es_5_6_qna": "/blog/technical/interview_questions_and_answers/generic/programming_languages",
  "jenkins": "/blog/technical/util",
  "jenkins_qna": "/blog/technical/interview_questions_and_answers/generic/devops",
  "jerryscript": "/blog/technical/embedded_system",
  "jest": "/blog/technical/frameworks_and_libraries/testing",
  "jharkhand": "/blog/non_technical/places_to_visit/eastern_india",
  "jquery": "/blog/technical/frameworks_and_libraries/javascript",
  "jquery_qna": "/blog/technical/interview_questions_and_answers/generic/web_development",
  "jscodeshift": "/blog/technical/frameworks_and_libraries/utility",
  "json_schema": "/blog/technical/frameworks_and_libraries/miscellaneous",
  "jsp": "/blog/technical/language",
  "kafka": "/blog/technical/util",
  "karnataka": "/blog/non_technical/places_to_visit/southern_india",
  "karpuri_thakur": "/blog/non_technical/characters/real",
  "kerala": "/blog/non_technical/places_to_visit/southern_india",
  "knockout": "/blog/technical/frameworks_and_libraries/javascript",
  "kubernetes": "/blog/technical/tools",
  "kubernetes_qna": "/blog/technical/interview_questions_and_answers/generic/cloud",
  "ladakh": "/blog/non_technical/places_to_visit/northern_india",
  "lakshadweep": "/blog/non_technical/places_to_visit/southern_india",
  "lal_bahadur_shastri": "/blog/non_technical/characters/real",
  "landscape_of_online_dating_and_relationships": "/blog/non_technical/chaos/questionnaire/dating",
  "learning_hindi": "/blog/non_technical/topics",
  "learning_sanskrit": "/blog/non_technical/topics",
  "less": "/blog/technical/frameworks_and_libraries/css",
  "lesson_from_hinduism": "/blog/non_technical/spiritual-science",
  "lifestyle_entertainment_and_online_services": "/blog/non_technical/chaos/topics/internet_guide",
  "line_by_line": "/blog/technical/frameworks_and_libraries/miscellaneous",
  "linkedlist": "/blog/technical/concept",
  "linkedlist_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "load_balancer": "/blog/technical/cloud",
  "lodash": "/blog/technical/frameworks_and_libraries/javascript",
  "log_rocket": "/blog/technical/util",
  "lord_brahma": "/blog/non_technical/characters/divine",
  "lord_hanuman": "/blog/non_technical/characters/divine",
  "lord_krishna": "/blog/non_technical/characters/divine",
  "lord_rama": "/blog/non_technical/characters/divine",
  "lord_shiva": "/blog/non_technical/characters/divine",
  "lord_vishnu": "/blog/non_technical/characters/divine",
  "love": "/blog/non_technical/concept",
  "maa_durga": "/blog/non_technical/characters/divine",
  "maa_lakshmi": "/blog/non_technical/characters/divine",
  "maa_saraswati": "/blog/non_technical/characters/divine",
  "madhya_pradesh": "/blog/non_technical/places_to_visit/central_india",
  "maha_mritunjay_mantra": "/blog/non_technical/mantra",
  "maharashtra": "/blog/non_technical/places_to_visit/western_india",
  "major_online_platforms": "/blog/non_technical/chaos/topics/online_plateforms",
  "manipur": "/blog/non_technical/places_to_visit/north_eastern_india",
  "manual_tester": "/blog/technical/roles_and_position",
  "manual_tester_qna": "/blog/technical/interview_questions_and_answers/generic/testing_and_qa",
  "map": "/blog/technical/frameworks_and_libraries/utility",
  "mastering_seo_strategies_for_success": "/blog/non_technical/chaos/questionnaire/seo",
  "material_design": "/blog/technical/frameworks_and_libraries/css",
  "meghalaya": "/blog/non_technical/places_to_visit/north_eastern_india",
  "micro_ui": "/blog/technical/process",
  "microcontroller_raspberry_pi_connection_through_web_interface": "/blog/technical/embedded_system",
  "microsoft_azure": "/blog/technical/cloud",
  "mimamsa_darshan": "/blog/non_technical/darshans",
  "mixpannel": "/blog/technical/util",
  "mizoram": "/blog/non_technical/places_to_visit/north_eastern_india",
  "mocha": "/blog/technical/frameworks_and_libraries/testing",
  "mocky": "/blog/technical/frameworks_and_libraries/testing",
  "modern_online_services_and_trends": "/blog/non_technical/chaos/topics/online_services",
  "modern_online_services_cultural_icons_and_lifestyle_trends": "/blog/non_technical/chaos/topics/online_services",
  "modern_online_trends_and_global_influencers": "/blog/non_technical/chaos/topics/internet_guide",
  "modern_trends_and_dynamics_in_online_dating": "/blog/non_technical/chaos/questionnaire/dating",
  "modi_n_bjp": "/blog/non_technical/mix",
  "mongodb": "/blog/technical/db",
  "mongodb_qna": "/blog/technical/interview_questions_and_answers/generic/db",
  "multiverse": "/blog/non_technical/theory",
  "mva": "/blog/technical/process",
  "mvc": "/blog/technical/process",
  "mvvc": "/blog/technical/process",
  "mysql": "/blog/technical/db",
  "mysql_qna": "/blog/technical/interview_questions_and_answers/generic/db",
  "nagaland": "/blog/non_technical/places_to_visit/north_eastern_india",
  "nagraj": "/blog/non_technical/characters/fictional",
  "narendra_modi": "/blog/non_technical/characters/real",
  "navigating_the_world_of_internet_games": "/blog/non_technical/chaos/questionnaire/gaming",
  "network_analysis": "/blog/technical/security",
  "network_analysis_qna": "/blog/technical/interview_questions_and_answers/generic/security",
  "news_entertainment_technology_and_lifestyle": "/blog/non_technical/chaos/topics/internet_guide",
  "nextjs": "/blog/technical/frameworks_and_libraries/javascript",
  "nextjs_routing": "/blog/technical/frameworks_and_libraries/javascript",
  "nodejs": "/blog/technical/tools",
  "nodejs_qna": "/blog/technical/interview_questions_and_answers/generic/web_development",
  "noe4j": "/blog/technical/db",
  "nyaya_darshan": "/blog/non_technical/darshans",
  "odisha": "/blog/non_technical/places_to_visit/eastern_india",
  "online_platforms_and_trends": "/blog/non_technical/chaos/topics/online_plateforms",
  "online_resources_brands_and_personalities": "/blog/non_technical/chaos/topics/internet_guide",
  "online_services_and_entertainment_platforms": "/blog/non_technical/chaos/topics/online_services",
  "online_services_and_popular_trends": "/blog/non_technical/chaos/topics/online_services",
  "online_services_and_trends": "/blog/non_technical/chaos/topics/online_services",
  "online_services_media_and_lifestyle_trends": "/blog/non_technical/chaos/topics/online_services",
  "oops": "/blog/technical/concept",
  "oops_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "optimize_press": "/blog/technical/frameworks_and_libraries/utility",
  "oracle_10g_11g": "/blog/technical/db",
  "os": "/blog/technical/plateform",
  "os_backdoors": "/blog/technical/security",
  "os_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "overview_of_online_services_and_platforms": "/blog/non_technical/chaos/topics/online_services",
  "parmanu": "/blog/non_technical/characters/fictional",
  "passport": "/blog/technical/frameworks_and_libraries/miscellaneous",
  "phonegap": "/blog/technical/plateform",
  "php": "/blog/technical/language",
  "polymer": "/blog/technical/web",
  "port_scanning": "/blog/technical/security",
  "postcss": "/blog/technical/frameworks_and_libraries/css",
  "postgresql": "/blog/technical/db",
  "postgresql_qna": "/blog/technical/interview_questions_and_answers/generic/db",
  "pot_logic": "/blog/technical/concept",
  "power_of_influence": "/blog/non_technical/concept",
  "powerbi": "/blog/technical/data",
  "price_list": "/blog/services",
  "protractor": "/blog/technical/frameworks_and_libraries/testing",
  "proxy_node": "/blog/technical/security",
  "psycho_movie": "/blog/non_technical/movie_story/horror",
  "pubsub": "/blog/technical/process",
  "puducherry": "/blog/non_technical/places_to_visit/southern_india",
  "pug": "/blog/technical/frameworks_and_libraries/utility",
  "punjab": "/blog/non_technical/places_to_visit/northern_india",
  "pwa": "/blog/technical/concept",
  "pycharm": "/blog/technical/tools",
  "python": "/blog/technical/language",
  "python_qna": "/blog/technical/interview_questions_and_answers/generic/programming_languages",
  "qa": "/blog/technical/roles_and_position",
  "qa_qna": "/blog/technical/interview_questions_and_answers/generic/job_roles",
  "rajasthan": "/blog/non_technical/places_to_visit/western_india",
  "raspberry_pi_3": "/blog/technical/embedded_system",
  "razorpay": "/blog/technical/frameworks_and_libraries/miscellaneous",
  "react_native": "/blog/technical/plateform",
  "react_native_plugin_development": "/blog/technical/plateform",
  "react_native_qna": "/blog/technical/interview_questions_and_answers/generic/web_development",
  "reactjs_15_x_latest": "/blog/technical/frameworks_and_libraries/javascript",
  "reactjs_15_x_latest_qna": "/blog/technical/interview_questions_and_answers/generic/web_development",
  "redux": "/blog/technical/frameworks_and_libraries/javascript",
  "redux_qna": "/blog/technical/interview_questions_and_answers/generic/web_development",
  "removing_fingerprint": "/blog/technical/security",
  "removing_footprint": "/blog/technical/security",
  "request": "/blog/technical/frameworks_and_libraries/utility",
  "require_js": "/blog/technical/frameworks_and_libraries/javascript",
  "robot_framework": "/blog/technical/frameworks_and_libraries/testing",
  "robot_framework_qna": "/blog/technical/interview_questions_and_answers/generic/testing_and_qa",
  "role_of_bjp_in_india_development": "/blog/non_technical/mix",
  "route_rerouting": "/blog/technical/security",
  "samkhya_darshan": "/blog/non_technical/darshans",
  "saraswati_vandana": "/blog/non_technical/mantra",
  "sass": "/blog/technical/frameworks_and_libraries/css",
  "school_and_preschool_education": "/blog/non_technical/chaos/topics/internet_guide",
  "school_education_in_india_and_beyond": "/blog/non_technical/chaos/questionnaire/education",
  "school_education_systems_and_institutions": "/blog/non_technical/chaos/questionnaire/education",
  "school_education_systems_and_related_aspects": "/blog/non_technical/chaos/questionnaire/education",
  "score_management": "/blog/technical/concept",
  "sdlc": "/blog/technical/process",
  "sdlc_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "selenium": "/blog/technical/tools",
  "selenium_qna": "/blog/technical/interview_questions_and_answers/generic/testing_and_qa",
  "sentry": "/blog/technical/util",
  "seo_mastery_techniques_importance_and_best_practices": "/blog/non_technical/chaos/questionnaire/seo",
  "shakti": "/blog/non_technical/characters/fictional",
  "shaktiman": "/blog/non_technical/characters/fictional",
  "shanti_mantra": "/blog/non_technical/mantra",
  "shell_scripts": "/blog/technical/language",
  "shell_scripts_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "shivoham": "/blog/non_technical/mantra",
  "shouldjs": "/blog/technical/frameworks_and_libraries/testing",
  "sicence": "/blog/technical/data",
  "sikkim": "/blog/non_technical/places_to_visit/north_eastern_india",
  "skeleton": "/blog/technical/frameworks_and_libraries/css",
  "smart_fox_multiplayer_game_server": "/blog/technical/tools",
  "social_media_online_marketplaces": "/blog/non_technical/chaos/topics/internet_guide",
  "socket_io": "/blog/technical/frameworks_and_libraries/javascript",
  "software_tester": "/blog/technical/roles_and_position",
  "software_tester_qna": "/blog/technical/interview_questions_and_answers/generic/job_roles",
  "solid": "/blog/technical/process",
  "spectre": "/blog/technical/frameworks_and_libraries/css",
  "sql": "/blog/technical/db",
  "sql_joins": "/blog/technical/db",
  "sql_joins_qna": "/blog/technical/interview_questions_and_answers/generic/db",
  "sql_qna": "/blog/technical/interview_questions_and_answers/generic/db",
  "sql_query": "/blog/technical/db",
  "sql_query_qna": "/blog/technical/interview_questions_and_answers/generic/db",
  "ssr": "/blog/technical/concept",
  "string_theory": "/blog/non_technical/theory",
  "stripe": "/blog/technical/frameworks_and_libraries/miscellaneous",
  "subhash_chandra_bose": "/blog/non_technical/characters/real",
  "super_commando_dhruva": "/blog/non_technical/characters/fictional",
  "system_engineer": "/blog/technical/roles_and_position",
  "system_engineer_qna": "/blog/technical/interview_questions_and_answers/generic/job_roles",
  "tackling_corruption_in_india": "/blog/non_technical/mix",
  "tamil_nadu": "/blog/non_technical/places_to_visit/southern_india",
  "tat_tvam_asi": "/blog/non_technical/mantra",
  "technical_support": "/blog/services",
  "technology_s_influence_and_impact": "/blog/non_technical/chaos/questionnaire/technology",
  "technology_sports_lifestyle": "/blog/non_technical/chaos/topics/technology",
  "telangana": "/blog/non_technical/places_to_visit/southern_india",
  "terraform": "/blog/technical/tools",
  "terraform_qna": "/blog/technical/interview_questions_and_answers/generic/cloud",
  "the_evolving_landscape_of_online_dating": "/blog/non_technical/chaos/questionnaire/dating",
  "the_infinite_wholeness": "/blog/non_technical/mantra",
  "the_joker": "/blog/non_technical/characters/fictional",
  "the_journey_to_ram_mandir": "/blog/non_technical/topics",
  "the_multifaceted_world_of_technology_and_its_impact": "/blog/non_technical/chaos/questionnaire/technology",
  "the_ultimate_guide_to_popular_questions": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "the_ultimate_guide_to_popular_topics_and_faqs": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "the_ultimate_how_to_guide": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "the_world_of_internet_gaming": "/blog/non_technical/chaos/questionnaire/gaming",
  "time_hindu": "/blog/non_technical/spiritual-science",
  "timeline_of_major_financial_scams": "/blog/non_technical/mix",
  "tiranga_the_patriotic_protector_of_raj_comics": "/blog/non_technical/characters/fictional",
  "tnc": "/blog",
  "tools_of_maya": "/blog/non_technical/types_of",
  "tortoise_svn_client": "/blog/technical/tools",
  "trends_entertainment_and_key_services_in_the_digital_age": "/blog/non_technical/chaos/topics/digital_edge",
  "tripura": "/blog/non_technical/places_to_visit/north_eastern_india",
  "turn_js": "/blog/technical/frameworks_and_libraries/utility",
  "twitter_bootstrap": "/blog/technical/frameworks_and_libraries/css",
  "type_of_viddya": "/blog/non_technical/types_of",
  "types_of_ahuti": "/blog/non_technical/types_of",
  "types_of_bhakti": "/blog/non_technical/types_of",
  "types_of_desire": "/blog/non_technical/types_of",
  "types_of_dharma": "/blog/non_technical/types_of",
  "types_of_doshas": "/blog/non_technical/types_of",
  "types_of_dukh": "/blog/non_technical/types_of",
  "types_of_gunas": "/blog/non_technical/types_of",
  "types_of_hawan": "/blog/non_technical/types_of",
  "types_of_lokas": "/blog/non_technical/types_of",
  "types_of_paap": "/blog/non_technical/types_of",
  "types_of_punya": "/blog/non_technical/types_of",
  "types_of_sukh": "/blog/non_technical/types_of",
  "types_of_tapas": "/blog/non_technical/types_of",
  "types_of_yagya": "/blog/non_technical/types_of",
  "types_of_yoga": "/blog/non_technical/types_of",
  "typescript": "/blog/technical/language",
  "typescript_qna": "/blog/technical/interview_questions_and_answers/generic/programming_languages",
  "ultimate_guide_to_common_qn": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "ultimate_guide_to_everyday_internet_queries": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "ultimate_how_to_guide_and_celebrity_insights": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "ultimate_life_hacks_and_tips": "/blog/non_technical/chaos/questionnaire/internet_guide",
  "undefined": "/blog/technical/concept",
  "underscore_js": "/blog/technical/frameworks_and_libraries/javascript",
  "understanding_seo": "/blog/non_technical/chaos/questionnaire/seo",
  "unit_testing": "/blog/technical/concept",
  "unit_testing_qna": "/blog/technical/interview_questions_and_answers/generic/testing_and_qa",
  "unity": "/blog/technical/tools",
  "universe_of_raj_comics": "/blog/non_technical/characters/fictional",
  "uttar_pradesh": "/blog/non_technical/places_to_visit/northern_india",
  "uttarakhand": "/blog/non_technical/places_to_visit/northern_india",
  "v_logic": "/blog/technical/process",
  "vaisheshika_darshan": "/blog/non_technical/darshans",
  "vash_movie_explained_in_hindi": "/blog/non_technical/movie_story/horror",
  "vedanta_darshan": "/blog/non_technical/darshans",
  "vishishtadvaita_darshan": "/blog/non_technical/darshans",
  "visual_studio": "/blog/technical/tools",
  "vos": "/blog/technical/embedded_system",
  "vos_kernel_programming": "/blog/technical/embedded_system",
  "vos_media_micro_services": "/blog/technical/embedded_system",
  "vuejs": "/blog/technical/frameworks_and_libraries/javascript",
  "vuejs_qna": "/blog/technical/interview_questions_and_answers/generic/web_development",
  "waterfall": "/blog/technical/process",
  "web_api": "/blog/technical/concept",
  "web_api_qna": "/blog/technical/interview_questions_and_answers/generic/mix",
  "web_components": "/blog/technical/web",
  "web_design_and_hosting": "/blog/non_technical/chaos/questionnaire/technology",
  "web_design_insights_and_strategies": "/blog/non_technical/chaos/questionnaire/technology",
  "web_designer": "/blog/technical/roles_and_position",
  "web_designer_qna": "/blog/technical/interview_questions_and_answers/generic/job_roles",
  "web_developer": "/blog/technical/roles_and_position",
  "web_developer_qna": "/blog/technical/interview_questions_and_answers/generic/job_roles",
  "web_development_certifications_tools_salaries_and_trends": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_companies_careers_and_skills": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_essentials_and_best_practices": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_from_learning_to_professional_growth": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_from_magento_to_mobile": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_insights_trends_and_opportunities": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_key_concepts_tools_and_opportunities": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_roles_skills_and_trends": "/blog/non_technical/chaos/topics/technology",
  "web_development_skills_trends_and_career_paths": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_trends_careers_and_best_practices": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_trends_careers_and_insights_part1": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_trends_careers_and_insights_part2": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_trends_careers_and_opportunities": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_trends_careers_and_technologies": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_trends_tools_and_careers": "/blog/non_technical/chaos/questionnaire/technology",
  "web_development_trends_tools_and_opportunities": "/blog/non_technical/chaos/questionnaire/technology",
  "web_hosting": "/blog/services",
  "web_scanning": "/blog/technical/security",
  "webpack": "/blog/technical/frameworks_and_libraries/task_runners_and_build_tools",
  "webrtc": "/blog/technical/frameworks_and_libraries/miscellaneous",
  "website_building": "/blog/services",
  "website_security_analyst": "/blog/technical/security",
  "webstorm": "/blog/technical/tools",
  "west_bengal": "/blog/non_technical/places_to_visit/eastern_india",
  "wiring_pi": "/blog/technical/embedded_system",
  "wordpress": "/blog/technical/util",
  "working_from_home_earning_money_and_staying_productive": "/blog/non_technical/chaos/questionnaire/working_from_home",
  "working_from_home_opportunities_tips_and_legitimacy": "/blog/non_technical/chaos/questionnaire/working_from_home",
  "world_of_software_a_comprehensive_guide": "/blog/non_technical/chaos/questionnaire/technology",
  "xcode": "/blog/technical/tools",
  "yoga_darshan": "/blog/non_technical/darshans",
  "zing_chart": "/blog/technical/frameworks_and_libraries/charting_and_visualization",
  "zookeeper": "/blog/technical/cloud"
};