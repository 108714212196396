import React, { useEffect, useState } from 'react';
import { getRandomElementFromArray, toggleLoader } from '../../utils';
import { blogNamePathMap } from '../../utils/constants/builtFromTop/blogNamePathMap';
import { blogImageMap } from '../../utils/constants/builtFromTop/imageBlog';
import { blogPathTitleMap } from '../../utils/constants/skillMeta';
import { ImgComp } from '../Img';
import { getName, getSkills } from '../util';

const CoderLogo = () => {
  const nm = getName();
  const [anim, setAnim] = useState(
    getRandomElementFromArray(['coder-scale-margin', 'coder-scale-opec', 'coder-scale-rotate', 'coder-translate']),
  );
  const [skills] = useState(getSkills());
  const [skillsSM] = useState(getSkills(true));
  const getHref = (sk: string) => {
    const p = blogNamePathMap[sk];
    p || console.log(`Missing sk:${sk}`);
    return `${p}/${sk}.html`;
  };
  const skillComp = (skls, cls?: string) =>
    (skls || []).map((sk, ind) => (
      <a href={getHref(sk)} target="_blank" rel="noopener noreferrer" className={`tech-parent ${cls}`} key={ind}>
        <ImgComp
          src={`icon/coder/512x512/${blogImageMap[sk]}.png`}
          className="sprite-img tooltip tooltip-top"
          alt={sk}
          data-tooltip={blogPathTitleMap[sk]}
        />
      </a>
    ));
  useEffect(() => {
    toggleLoader(false, () => setAnim(''));
  }, []);
  return (
    <>
      <div className={`coder-logo coder-logo-lg ${anim}`}>
        <div>{skillComp(skills[0])}</div>
        <div>{skillComp(skills[1])}</div>
        <div>{skillComp(skills[2])}</div>
        <div>
          {skillComp(skills[6])}
          <div className="tech-parent">
            <div className={`sprite-img brand-name ${nm.name.join('-')}`} title={nm.name.join(' ')}>
              <div className="www">www.</div>
              <div className="brand-name-value">{nm.camel}</div>
              <div className="com">.com</div>
            </div>
          </div>
          {skillComp(skills[7])}
        </div>
        <div>{skillComp(skills[3])}</div>
        <div>{skillComp(skills[4])}</div>
        <div>{skillComp(skills[5])}</div>
      </div>
      <div className={`coder-logo coder-logo-sm columns ${anim}`}>
        <div className="column col-12">
          <div className="tech-parent">
            <div className={`sprite-img brand-name ${nm.name.join('-')}`} title={nm.name.join(' ')}>
              <div className="www">www.</div>
              <div className="brand-name-value">{nm.camel}</div>
              <div className="com">.com</div>
            </div>
          </div>
        </div>
        <div className="column col-12 columns">
          {skillsSM.map(sk => skillComp([sk], 'column col-xs-4 col-sm-3 col-lg-2'))}
        </div>
      </div>
    </>
  );
};

export { CoderLogo };
