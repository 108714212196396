import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { IconLogo } from '../IconLogo';
import { ImgComp } from '../Img';

interface ICustomLink {
  path: string | string[];
  name: string;
  icon: string;
  abs?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top';
  forceActive?: boolean;
  className?: string;
  onNavChange?: (newPath?: string) => void;
}

const CustomLink = ({ path, abs, name, icon, className, forceActive, onNavChange, target }: ICustomLink) => {
  const _location = useLocation();
  const p = Array.isArray(path) ? path : [path];
  const isActiveNav = p.includes(`${_location.pathname}`);
  const handleClick = () => {
    !isActiveNav && onNavChange && onNavChange(`${_location.pathname}`);
  };
  return abs ? (
    <a
      href={`${path}`}
      target={target || '_blank'}
      rel="noopener noreferrer"
      className={`${forceActive ? 'force-active' : ''} in-active`}
    >
      <ImgComp alt={name} className="icon icon-lg" src={icon} onClick={handleClick} />
      <span className="hidden-lg" onClick={handleClick}>
        {name}
      </span>
    </a>
  ) : (
    <Link
      to={p[p.length - 1]}
      className={`${forceActive ? 'force-active' : ''} ${isActiveNav ? 'active' : 'in-active'} ${className || ''}`}
    >
      <ImgComp alt={name} className="icon icon-lg" src={icon} onClick={handleClick} />
      <span className="hidden-lg" onClick={handleClick}>
        {name}
      </span>
    </Link>
  );
};

interface INav {
  className?: string;
  onNavChange?: (newPath?: string) => void;
}

export const Nav = ({ onNavChange, className }: INav) => {
  const comProps = {
    className: className || '',
    onNavChange,
  };
  return (
    <>
      <IconLogo />
      <CustomLink
        path={['/', '/home']}
        name="Technologies I'm proficent in"
        icon="icon/processor-chip.png"
        {...comProps}
      />
      <CustomLink path="/profile" name="My profile" icon="icon/brain-tech.png" {...comProps} />
      <CustomLink path="/social" name="Contact me" icon="icon/phone-message.png" {...comProps} />
      <CustomLink path="/drinkwithme" name="Drink with me" icon="icon/cheers.png" {...comProps} />
      <CustomLink path="/blog/index.html" name="My blogs" icon="icon/blog.png" abs {...comProps} />
      <CustomLink
        path="/blog/services/index.html"
        name="Our Services"
        icon="icon/coder/512x512/services.png"
        abs
        {...comProps}
      />
      <CustomLink path="/sitemap.html" name="Sitemap" icon="icon/coder/512x512/sitemap.png" abs {...comProps} />
      <CustomLink
        path="/blog/tnc.html"
        name="Term and conditions"
        icon="icon/agreement.png"
        target="_self"
        abs
        {...{ ...comProps, className: `${className} tnc` }}
      />
      <CustomLink
        path="/wip"
        name="Website is under construction"
        icon="icon/work-in-progress.png"
        forceActive
        {...{ ...comProps, className: `${className} work-in-progress"` }}
      />
    </>
  );
};
