import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import './builtStyle/index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Resume, { ResumePages } from './components/Pages/Resume';
import reportWebVitals from './reportWebVitals';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Resume />} />
        <Route path="*" element={<Resume type={ResumePages.HOME} />} />
        <Route path="home" element={<Resume type={ResumePages.HOME} />} />
        <Route path="profile" element={<Resume type={ResumePages.DETAILS} />} />
        <Route path="social" element={<Resume type={ResumePages.SOCIAL} />} />
        <Route path="chat" element={<Resume type={ResumePages.CHAT} />} />
        <Route path="drinkwithme" element={<Resume type={ResumePages.DWM} />} />
        <Route path="wip" element={<Resume type={ResumePages.WIP} />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
