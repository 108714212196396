import React from 'react';

interface IMenuIcon {
  className?: string;
  closedState?: boolean;
  onClick: () => void;
}

export const MenuIcon = ({ className, closedState, onClick, ...rest }: IMenuIcon) => {
  return (
    <div className={className}>
      <div className={`menu-toggle ${closedState ? '' : 'opened'}`} onClick={onClick}>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </div>
  );
};
