export const blogImageMap = {
 "3n_plus_1": "3n_plus_1",
 "11dimen": "11dimen",
 "generic-interview-qna": "interview",
 "library-and-framework": "javascript",
 "must-know": "coding",
 "must-know-interview-qna": "interview",
 "spiritual-science": "scientist",
 "your.cardtherapy": "tarot",
 "_DOMAIN_PLACE_HOLDER_FULL_DOMAIN_": "pp2",
 "a_comprehensive_internet_guide": "digital",
 "a_guide_to_online_services_platforms_and_trends": "digital",
 "Aboutme": "tarot",
 "achintya_bheda_abheda_darshan": "power_of_influence",
 "advaita_darshan": "power_of_influence",
 "agile_methodology": "agile",
 "agile_methodology_qna": "agile",
 "AkhilResume_l": "brain-tech",
 "AkhilResume_s": "brain-tech",
 "all_major_database": "database",
 "am_chart": "am_chart",
 "andaman_and_nicobar_islands": "andaman_and_nicobar_islands",
 "andhra_pradesh": "andhra_pradesh",
 "android": "android",
 "android_ios_native_app_development": "mobile-development",
 "android_studio": "android-studio",
 "angular_1_x_2_latest": "angular",
 "angular_1_x_2_latest_qna": "angular",
 "answers_to_popular_internet_questions": "digital",
 "apache_maven": "maven",
 "apache_shindig": "apache",
 "apache_tomcat": "apache",
 "arunachal_pradesh": "arunachal_pradesh",
 "assam": "assam",
 "assert": "assert",
 "async": "javascript",
 "atal_bihari_vajpayee": "atal_bihari_vajpayee",
 "atomic_design": "atomic-design",
 "automation_testing": "test",
 "automation_testing_qna": "test",
 "aws_ec2_cloud9_iam_s3_lambda_function_cloudfront_route53": "aws",
 "aws_ec2_cloud9_iam_s3_lambda_function_cloudfront_route53_qna": "aws",
 "aws_lambda": "aws",
 "aws_lambda_qna": "aws",
 "azure": "azure",
 "back_end_front_end_protection": "web_protection",
 "backbone": "backbone",
 "batman_characters": "batman",
 "bhagat_singh": "bhagat_singh",
 "bhagwat_geeta": "book",
 "big_data": "big_data",
 "bihar": "bihar",
 "bihar_history": "bihar",
 "bihar_jurney": "bihar",
 "bihar_n_science": "bihar",
 "bihar_unseen": "bihar",
 "bitbucket": "bitbucket",
 "blender": "blender",
 "blog": "blog",
 "browser_extensions": "browsers",
 "build_fix": "build-and-fix-model",
 "c": "c",
 "c_panel": "cpanel-hosting",
 "c_qna": "c",
 "career_counseling": "career_counseling",
 "central_india": "india",
 "chacha_chaudhary": "chacha_chaudhary",
 "chaijs": "chai",
 "chandigarh": "chandigarh",
 "chandra_shekhar_azad": "chandra_shekhar_azad",
 "chaos": "chaos_theory",
 "chaos_theory": "chaos_theory",
 "characters": "characters",
 "chart_js": "chartjs",
 "charting_and_visualization": "data",
 "chhattisgarh": "chhattisgarh",
 "chrome_developer_tool": "chrome-dev-logo-icon",
 "ci_cd": "clustered",
 "ci_cd_qna": "clustered",
 "cloud": "gcp",
 "clustered_server": "clustered",
 "comprehensive_guide_to_common_queries": "digital",
 "comprehensive_how_to_guide_answers_to_everyday_questions": "digital",
 "concept": "power_of_influence",
 "constructor_pattern": "constructor",
 "contactus": "tarot",
 "contemporary_online_platforms_and_trends": "digital",
 "cordova": "cordova",
 "cordova_plugin": "cordova",
 "cpp": "cpp",
 "cpp_qna": "cpp",
 "crack_angular": "interview",
 "crack_angularjs": "interview",
 "crack_css": "interview",
 "crack_html": "interview",
 "crack_js": "interview",
 "crack_mix": "interview",
 "crack_react": "interview",
 "cryptography": "cryptography",
 "css3": "css",
 "css3_qna": "css",
 "css": "css",
 "current_online_platforms_and_personalities": "digital",
 "current_trends_and_insights_in_online_dating": "dating",
 "cx_module": "cisco",
 "cybersecurity_essentials": "cyber-attack",
 "cybersecurity_essentials_qna": "cyber-attack",
 "d3_js": "d3",
 "dadra_and_nagar_haveli": "dadra_and_nagar_haveli",
 "daman_and_diu": "daman_and_diu",
 "darshans": "scientist",
 "data": "data",
 "data_analysis": "data",
 "data_cleaner": "data-cleaning",
 "data_cleansing": "data-cleaning",
 "data_dog": "datadog",
 "data_optimization": "data_optimization",
 "data_processing": "data_optimization",
 "data_structure": "hierarchical",
 "data_structure_qna": "hierarchical",
 "data_visualization": "monitor",
 "database_connectors": "plug-in",
 "database_snooping": "snooping",
 "dating": "dating",
 "db2": "ibm-db2-logo",
 "db2_qna": "ibm-db2-logo",
 "db": "database",
 "decryption": "decryption",
 "delhi": "delhi",
 "design_patterns": "hierarchical",
 "design_patterns_qna": "hierarchical",
 "devops": "coding",
 "devops_qna": "coding",
 "dhootha_explained_in_hindi": "film",
 "digital_edge": "digital",
 "digital_media_and_sporting_goods": "digital",
 "digital_media_current_events_and_lifestyle_trends": "digital",
 "digital_services_and_cultural_trends_comprehensive_exploration": "digital",
 "digital_services_brands_and_personalities": "digital",
 "digital_trends": "digital",
 "digital_trends_and_global_influences": "digital",
 "discovering_india": "india",
 "diverse_spectrum_of_online_dating": "dating",
 "diverse_world_of_online_dating": "dating",
 "divine": "om",
 "mantra": "om",
 "aham_brahmasmi": "lord_brahma",
 "dual_path_of_dharma": "lord_krishna",
 "embracing_the_divine": "lord_shiva",
 "eternal_promise_of_divine_intervention": "lord_krishna",
 "maha_mritunjay_mantra": "lord_shiva",
 "shivoham": "lord_shiva",
 "saraswati_vandana": "maa_saraswati",
 "maa_saraswati": "maa_saraswati",
 "maa_lakshmi": "maa_lakshmi",
 "lord_brahma": "lord_brahma",
 "shanti_mantra": "om",
 "tat_tvam_asi": "om",
 "the_infinite_wholeness": "om",
 "dns_analysis": "dns",
 "dns_management": "dns",
 "psycho_movie": "film",
 "docker": "docker",
 "types_of": "sitemap",
 "types_of_desire": "desire",
 "types_of_bhakti": "bhakti",
 "types_of_hawan": "hawan",
 "types_of_dharma": "om",
 "types_of_doshas": "panch_mahabhoot",
 "types_of_dukh": "dukh",
 "tools_of_maya": "maya",
 "types_of_gunas": "panch_mahabhoot",
 "types_of_lokas": "lokas",
 "types_of_paap": "paap_punya",
 "types_of_punya": "paap_punya",
 "types_of_sukh": "happy",
 "types_of_tapas": "tapas",
 "types_of_yoga": "tapas",
 "types_of_ahuti": "hawan",
 "types_of_yagya": "hawan",
 "type_of_viddya": "graduation",
 "docker_qna": "docker",
 "doga": "doga",
 "dr_apj_abdul_kalam": "dr_apj_abdul_kalam",
 "drinkwithme": "cheers",
 "dvaita_darshan": "power_of_influence",
 "dvaitadvaita_darshan": "power_of_influence",
 "dynamic_world_of_online_dating": "dating",
 "eastern_india": "india",
 "eclipse": "lunar",
 "education": "graduation",
 "elastic_search": "elastic",
 "elastic_search_qna": "elastic",
 "embedded": "embedded",
 "embedded_system": "embedded",
 "emotion_purpose": "emotions",
 "emotions": "emotions",
 "encryption": "encrypted",
 "engineering_manager": "engineering_manager",
 "engineering_manager_qna": "engineering_manager",
 "es6": "javascript",
 "es6_qna": "javascript",
 "ethical_hacking": "ethical_hacking",
 "evolving_world_of_online_dating": "dating",
 "expect": "javascript",
 "exploring_popular_topics_from_tech_to_entertainment": "digital",
 "exploring_the_world_of_internet_gaming": "games",
 "express": "express",
 "faqs": "tarot",
 "fictional": "power_of_influence",
 "flux": "capacitor",
 "frameworks_and_libraries": "data",
 "free_websites_diverse_options_and_trends": "digital",
 "from_antivirus_to_marketing": "digital",
 "from_free_sites_to_matchmaking_services": "digital",
 "from_sims_to_online_sites_and_apps": "dating",
 "from_sports_to_technology": "digital",
 "front_end_developer": "coding",
 "front_end_developer_qna": "coding",
 "full_stack_developer": "coding",
 "full_stack_developer_qna": "coding",
 "functional_testing": "test",
 "functional_testing_qna": "test",
 "game_logic": "games",
 "gaming": "games",
 "garuda_purana": "book",
 "gcp": "gcp",
 "gcp_qna": "gcp",
 "generic": "coding",
 "gin": "gin",
 "git": "git",
 "git_api_webhooks": "webhook",
 "git_qna": "git",
 "github": "mark",
 "gitlab": "gitlab",
 "global_landscape_of_tech_careers_salaries_opportunities_and_skills": "graduation",
 "goa": "goa",
 "golang": "golang",
 "golang_qna": "golang",
 "google_api_translation_gcp_map_etc": "google",
 "google_chart": "google",
 "gorilla": "gorilla",
 "gradle": "gradle",
 "grunt": "grunt",
 "grunt_cefe_mocha": "grunt",
 "gujarat": "gujarat",
 "gulp": "gulp",
 "hana": "hana",
 "handlebar": "javascript",
 "harley_quinn": "harley_quinn",
 "harmony_between_hinduism_and_buddhism": "om",
 "harmony_between_hinduism_and_judaism": "om",
 "harmony_of_hinduism_and_sikhism": "om",
 "harry_potter_and_voldemort": "harry",
 "haryana": "haryana",
 "healthy_life": "healthy_life",
 "high_chart": "data",
 "himachal_pradesh": "himachal_pradesh",
 "hindu_science": "om",
 "hinduism_in_world_fabric": "om",
 "hindus_and_sikhs_in_mughal_dynasty": "om",
 "holographic_univ": "holographic_univ",
 "home": "processor-chip",
 "horcrux": "harry",
 "horror": "horror",
 "html5": "html",
 "html5_qna": "html",
 "html_email": "file",
 "hybrid_app_development": "mobile-development",
 "hyperapp": "javascript",
 "hypernova": "javascript",
 "immutablejs": "javascript",
 "index": "list",
 "india": "india",
 "india_in_glance": "india",
 "india_into_world_fabric": "india",
 "india_n_science": "india",
 "indira_gandhi": "indira_gandhi",
 "informix": "informix",
 "insights_and_practical_information": "digital",
 "insights_into_popular_online_platforms_and_services": "digital",
 "internet_games": "games",
 "internet_guide": "digital",
 "interview": "interview",
 "interview_questions_and_answers": "coding",
 "ionic": "ionic",
 "ios": "ios",
 "iot": "iot",
 "iot_qna": "iot",
 "jade": "javascript",
 "jammu_and_kashmir": "jammu_and_kashmir",
 "java": "java",
 "java_qna": "java",
 "javascript": "javascript",
 "javascript_es_5_6": "javascript",
 "javascript_es_5_6_qna": "javascript",
 "jenkins": "jenkins",
 "jenkins_qna": "jenkins",
 "jerryscript": "iot",
 "jest": "jest",
 "jharkhand": "jharkhand",
 "job_roles": "engineering_manager",
 "jquery": "jquery",
 "jquery_qna": "jquery",
 "jscodeshift": "javascript",
 "json_schema": "json",
 "jsp": "jsp",
 "kafka": "kafka",
 "karnataka": "karnataka",
 "karpuri_thakur": "karpuri_thakur",
 "kerala": "kerala",
 "knockout": "knockout",
 "kubernetes": "kubernetes",
 "kubernetes_qna": "kubernetes",
 "ladakh": "ladakh",
 "lakshadweep": "lakshadweep",
 "lal_bahadur_shastri": "lal_bahadur_shastri",
 "landscape_of_online_dating_and_relationships": "dating",
 "language": "vos",
 "learning_hindi": "hindi",
 "learning_sanskrit": "om",
 "less": "css",
 "lesson_from_hinduism": "om",
 "lifestyle_entertainment_and_online_services": "digital",
 "line_by_line": "javascript",
 "linkedlist": "coding",
 "linkedlist_qna": "coding",
 "load_balancer": "load-balaning",
 "lodash": "javascript",
 "log_rocket": "log_rocket",
 "lord_hanuman": "lord_hanuman",
 "lord_krishna": "lord_krishna",
 "lord_rama": "lord_ram",
 "lord_shiva": "lord_shiva",
 "lord_vishnu": "lord_vishnu",
 "love": "love",
 "maa_durga": "maa_durga",
 "madhya_pradesh": "madhya_pradesh",
 "maharashtra": "maharashtra",
 "major_online_platforms": "digital",
 "manipur": "manipur",
 "manual_tester": "test",
 "manual_tester_qna": "test",
 "map": "map",
 "mastering_seo_strategies_for_success": "coding",
 "material_design": "membrane",
 "meghalaya": "meghalaya",
 "micro_ui": "micro_ui",
 "microcontroller_raspberry_pi_connection_through_web_interface": "raspberry-pi",
 "microsoft_azure": "azure",
 "mimamsa_darshan": "power_of_influence",
 "miscellaneous": "chaos_theory",
 "mix": "chaos_theory",
 "mixpannel": "mixpannel",
 "mizoram": "mizoram",
 "mocha": "javascript",
 "mocky": "json",
 "modern_online_services_and_trends": "digital",
 "modern_online_services_cultural_icons_and_lifestyle_trends": "digital",
 "modern_online_trends_and_global_influencers": "digital",
 "modern_trends_and_dynamics_in_online_dating": "dating",
 "modi_n_bjp": "lotus",
 "mongodb": "mongo_db",
 "mongodb_qna": "mongo_db",
 "movie_story": "film",
 "multiverse": "multiverse",
 "must_know_interview": "interview",
 "mva": "mvc",
 "mvc": "mvc",
 "mvvc": "mvc",
 "mysql": "mysql",
 "mysql_qna": "mysql",
 "nagaland": "nagaland",
 "nagraj": "nagraj",
 "narendra_modi": "modi",
 "navigating_the_world_of_internet_games": "games",
 "network_analysis": "network-analysis",
 "network_analysis_qna": "network-analysis",
 "news_entertainment_technology_and_lifestyle": "digital",
 "nextjs": "next",
 "nextjs_routing": "next",
 "nodejs": "nodejs",
 "nodejs_qna": "nodejs",
 "noe4j": "noe4j",
 "non_technical": "power_of_influence",
 "north_eastern_india": "india",
 "northern_india": "india",
 "nyaya_darshan": "power_of_influence",
 "odisha": "odisha",
 "online_plateforms": "digital",
 "online_platforms_and_trends": "digital",
 "online_resources_brands_and_personalities": "digital",
 "online_services": "digital",
 "online_services_and_entertainment_platforms": "digital",
 "online_services_and_popular_trends": "digital",
 "online_services_and_trends": "digital",
 "online_services_media_and_lifestyle_trends": "digital",
 "oops": "oops",
 "oops_qna": "oops",
 "optimize_press": "optimize_press",
 "oracle_10g_11g": "oracle",
 "os": "processor-chip",
 "os_backdoors": "backdoor",
 "os_qna": "processor-chip",
 "overview_of_online_services_and_platforms": "digital",
 "parmanu": "parmanu",
 "passport": "passport",
 "phonegap": "phonegap",
 "php": "php",
 "places_to_visit": "tourism",
 "plateform": "mobile-development",
 "polymer": "polymer",
 "port_scanning": "port_scanning",
 "postcss": "css",
 "postgresql": "postgresql",
 "postgresql_qna": "postgresql",
 "pot_logic": "pot_logic",
 "power_of_influence": "power_of_influence",
 "powerbi": "powerbi",
 "price_list": "price-list",
 "process": "waterfall",
 "profile": "brain-tech",
 "programming_languages": "coding",
 "protractor": "protractor",
 "proxy_node": "proxy_node",
 "pubsub": "pubsub",
 "puducherry": "puducherry",
 "pug": "pug",
 "punjab": "punjab",
 "pwa": "pwa",
 "pycharm": "pycharm",
 "python": "python",
 "python_qna": "python",
 "qa": "test",
 "qa_qna": "test",
 "questionnaire": "confused",
 "rajasthan": "rajasthan",
 "raspberry_pi_3": "raspberry-pi",
 "razorpay": "razorpay",
 "react_native": "react",
 "react_native_plugin_development": "react",
 "react_native_qna": "react",
 "reactjs_15_x_latest": "react",
 "reactjs_15_x_latest_qna": "react",
 "real": "certified",
 "redux": "redux",
 "redux_qna": "redux",
 "removing_fingerprint": "removing_fingerprint",
 "removing_footprint": "removing_footprint",
 "request": "request",
 "require_js": "javascript",
 "res": "brain-tech",
 "robot_framework": "robot_framework",
 "robot_framework_qna": "robot_framework",
 "role_of_bjp_in_india_development": "lotus",
 "roles_and_position": "engineering_manager",
 "roles_n_position": "engineering_manager",
 "route_rerouting": "route_rerouting",
 "samkhya_darshan": "power_of_influence",
 "sass": "css",
 "school_and_preschool_education": "graduation",
 "school_education_in_india_and_beyond": "graduation",
 "school_education_systems_and_institutions": "graduation",
 "school_education_systems_and_related_aspects": "graduation",
 "score_management": "score_management",
 "sdlc": "hierarchical",
 "sdlc_qna": "hierarchical",
 "security": "cyber-attack",
 "selenium": "selenium",
 "selenium_qna": "selenium",
 "sentry": "sentry",
 "seo": "coding",
 "seo_mastery_techniques_importance_and_best_practices": "coding",
 "server_side": "clustered",
 "services": "services",
 "shakti": "shakti",
 "shaktiman": "shaktiman",
 "shell_scripts": "shell_scripts",
 "shell_scripts_qna": "shell_scripts",
 "shouldjs": "javascript",
 "sicence": "data-science",
 "sikkim": "sikkim",
 "sitemap": "sitemap",
 "skeleton": "css",
 "smart_fox_multiplayer_game_server": "smartfox",
 "social": "phone-message",
 "social_media_online_marketplaces": "digital",
 "socket_io": "socket",
 "software_tester": "test",
 "software_tester_qna": "test",
 "solid": "solid",
 "southern_india": "india",
 "spectre": "css",
 "sql": "sql",
 "sql_joins": "sql",
 "sql_joins_qna": "sql",
 "sql_qna": "sql",
 "sql_query": "sql",
 "sql_query_qna": "sql",
 "ssr": "clustered",
 "string_theory": "scientist",
 "stripe": "stripe",
 "subhash_chandra_bose": "subhash_chandra_bose",
 "super_commando_dhruva": "super_commando_dhruva",
 "system_engineer": "coding",
 "system_engineer_qna": "coding",
 "tackling_corruption_in_india": "tackling_corruption_in_india",
 "tamil_nadu": "tamil_nadu",
 "task_runners_and_build_tools": "coding",
 "technical": "data",
 "technical_support": "technical_support",
 "technology": "processor-chip",
 "technology_s_influence_and_impact": "digital",
 "technology_sports_lifestyle": "digital",
 "telangana": "telangana",
 "template_engines": "coding",
 "terraform": "terraform",
 "terraform_qna": "terraform",
 "testing": "coding",
 "testing_and_qa": "coding",
 "the_evolving_landscape_of_online_dating": "dating",
 "the_joker": "the_joker",
 "the_journey_to_ram_mandir": "lord_ram",
 "the_multifaceted_world_of_technology_and_its_impact": "digital",
 "the_ultimate_guide_to_everyday_questions": "confused",
 "the_ultimate_guide_to_popular_questions": "confused",
 "the_ultimate_guide_to_popular_topics_and_faqs": "confused",
 "the_ultimate_how_to_guide": "confused",
 "the_world_of_internet_gaming": "games",
 "theory": "power_of_influence",
 "time_hindu": "om_time",
 "timeline_of_major_financial_scams": "money",
 "tiranga_the_patriotic_protector_of_raj_comics": "tiranga_the_patriotic_protector",
 "tnc": "agreement",
 "tools": "services",
 "topics": "power_of_influence",
 "tortoise_svn_client": "tortoise_svn_client",
 "trends_entertainment_and_key_services_in_the_digital_age": "digital",
 "tripura": "tripura",
 "turn_js": "javascript",
 "twitter_bootstrap": "css",
 "typescript": "typescript",
 "typescript_qna": "typescript",
 "ultimate_guide_to_common_qn": "confused",
 "ultimate_guide_to_everyday_internet_queries": "confused",
 "ultimate_how_to_guide_and_celebrity_insights": "confused",
 "ultimate_life_hacks_and_tips": "confused",
 "undefined": "undefined",
 "underscore_js": "javascript",
 "understanding_seo": "coding",
 "unit_testing": "test",
 "unit_testing_qna": "test",
 "unity": "unity",
 "universe_of_raj_comics": "raj_comics",
 "util": "services",
 "utility": "coding",
 "uttar_pradesh": "uttar_pradesh",
 "uttarakhand": "uttarakhand",
 "v_logic": "micro_ui",
 "vaisheshika_darshan": "power_of_influence",
 "vash_movie_explained_in_hindi": "film",
 "vedanta_darshan": "power_of_influence",
 "vishishtadvaita_darshan": "power_of_influence",
 "visual_studio": "visual_studio",
 "vos": "vos",
 "vos_kernel_programming": "vos",
 "vos_media_micro_services": "vos",
 "vuejs": "vuejs",
 "vuejs_qna": "vuejs",
 "waterfall": "waterfall",
 "web": "browsers",
 "web_api": "api",
 "web_api_qna": "api",
 "web_components": "web_components",
 "web_design_and_hosting": "coding",
 "web_design_insights_and_strategies": "coding",
 "web_designer": "coding",
 "web_designer_qna": "coding",
 "web_developer": "coding",
 "web_developer_qna": "coding",
 "web_development": "website_building",
 "web_development_certifications_tools_salaries_and_trends": "coding",
 "web_development_companies_careers_and_skills": "coding",
 "web_development_essentials_and_best_practices": "coding",
 "web_development_from_learning_to_professional_growth": "coding",
 "web_development_from_magento_to_mobile": "coding",
 "web_development_insights_trends_and_opportunities": "coding",
 "web_development_key_concepts_tools_and_opportunities": "coding",
 "web_development_roles_skills_and_trends": "coding",
 "web_development_skills_trends_and_career_paths": "coding",
 "web_development_trends_careers_and_best_practices": "coding",
 "web_development_trends_careers_and_insights_part1": "coding",
 "web_development_trends_careers_and_insights_part2": "coding",
 "web_development_trends_careers_and_opportunities": "coding",
 "web_development_trends_careers_and_technologies": "coding",
 "web_development_trends_tools_and_careers": "coding",
 "web_development_trends_tools_and_opportunities": "coding",
 "web_hosting": "web_hosting",
 "web_scanning": "web_protection",
 "webpack": "webpack",
 "webrtc": "webrtc",
 "website_building": "website_building",
 "website_security_analyst": "web_protection",
 "webstorm": "webstorm",
 "west_bengal": "west_bengal",
 "western_india": "india",
 "wip": "work-in-progress",
 "wiring_pi": "raspberry-pi",
 "wordpress": "wordpress",
 "working_from_home": "coding",
 "working_from_home_earning_money_and_staying_productive": "coding",
 "working_from_home_opportunities_tips_and_legitimacy": "coding",
 "world_of_software_a_comprehensive_guide": "coding",
 "xcode": "xcode",
 "yoga_darshan": "power_of_influence",
 "zing_chart": "data",
 "zookeeper": "zookeeper"
};
export const servicesBlog = [
 "career_counseling",
 "price_list",
 "technical_support",
 "web_hosting",
 "website_building"
];
export const interestBlog = [
 "3n_plus_1",
 "11dimen",
 "bhagwat_geeta",
 "bihar_history",
 "bihar_jurney",
 "bihar_n_science",
 "bihar_unseen",
 "chaos_theory",
 "emotion_purpose",
 "emotions",
 "garuda_purana",
 "healthy_life",
 "hindu_science",
 "hinduism_in_world_fabric",
 "holographic_univ",
 "horcrux",
 "india_in_glance",
 "india_into_world_fabric",
 "india_n_science",
 "lesson_from_hinduism",
 "love",
 "modi_n_bjp",
 "multiverse",
 "power_of_influence",
 "string_theory",
 "the_joker",
 "time_hindu"
];
export const blogCategories = {
 "ALL": {
  "name": "All",
  "dirname": "/blog/",
  "dir_p": "",
  "link": "/blog/index.html"
 },
 "TECHNICAL": {
  "name": "Technical",
  "dirname": "/blog/technical/",
  "dir_p": "technical",
  "link": "index.html"
 },
 "NON_TECHNICAL": {
  "name": "Non-Technical",
  "dirname": "/blog/non_technical/",
  "dir_p": "non_technical",
  "link": "index.html"
 },
 "SERVICES": {
  "name": "Services",
  "dirname": "/blog/services/",
  "dir_p": "services",
  "link": "index.html"
 }
};
