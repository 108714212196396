import sanitizeHtml from 'sanitize-html';

export const senitizeConfig = {
  allowedTags: Array.from(
    new Set([
      ...sanitizeHtml.defaults.allowedTags,
      'font',
      'p',
      'em',
      'strong',
      'div',
      'span',
      'center',
      'svg',
      'path',
    ]),
  ),
  allowedAttributes: {
    '*': Array.from(
      new Set([
        ...Object.values(sanitizeHtml.defaults.allowedAttributes).flat(),
        'href',
        'align',
        'alt',
        'center',
        'bgcolor',
        'color',
        'style',
        'd',
        'viewBox',
        'class',
        'font-size',
      ]),
    ),
  },
  nestingLimit: 10,
};

export const saveSelection = () => {
  let sel;
  const doc: any = document;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      return sel.getRangeAt(0);
    }
  } else if (doc.selection && doc.selection.createRange) {
    return doc.selection.createRange();
  }
  return null;
};
export const resetSelection = () => {
  const sel = window.getSelection();
  sel?.removeAllRanges();
};

export const restoreSelection = (range: any) => {
  let sel;
  const doc: any = document;
  if (range) {
    if (window.getSelection) {
      sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (doc.selection && range.select) {
      range.select();
    }
  }
};
