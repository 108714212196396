import React from 'react';
import { Loader1 } from '../Loader';

export const Wip = () => {
  return (
    <>
      <Loader1 />
      <div className="h1 text-center">Under Construction</div>

      <p className="pad-10 text-center">
        We are currently working on our website and blogs to bring you exciting content. Please bear with us as we make
        improvements and changes. The design might evolve, and we appreciate your patience.
      </p>

      <div className="h4 text-center">Stay tuned for updates!</div>
      <div className="pad-10 text-center">
        <span>
          Please feel free to share any thoughts you may have; your feedback is invaluable in refining and improving the
          content
        </span>
        <a href="/social" className="btn btn-link">
          here
        </a>
      </div>
    </>
  );
};
