import React from 'react';

interface IImagePDFC {
  className: string;
  label?: string[];
}

export const StaticPdf = ({ className, label }: IImagePDFC) => {
  return (
    <div className="btn-group print-btn">
      <a className={className} target="_blank" href="/res/AkhilResume_l.pdf" style={{ top: 50 }} download>
        {(label && label[0]) || 'Download detailed Resume'}
      </a>
      <a className={className} target="_blank" href="/res/AkhilResume_s.pdf" download>
        {(label && label[1]) || 'Download concise Resume'}
      </a>
    </div>
  );
};
